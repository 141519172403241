import React, { FC } from 'react'

import { FormikProps } from 'formik'
import { Text, Box } from '@chakra-ui/react'

import { RepresentativeA } from './RepresentativeA'
import { RepresentativeB } from './RepresentativeB'
import { RepresentativeC } from './RepresentativeC'
import { RepresentativeType } from '@/interfaces'

interface Props {
    formik: FormikProps<FormikValues>
    representativesTypes: RepresentativeType
    segment: number
}

interface FormikValues {
    email: string
    phone: string
    name: string
    representatives: Representative[]
    client_type: string
}

interface Representative {
    name: string
    lastname: string
    second_lastname: string
    dni: string
    phone: string
    email: string
    representative_position: string
    notary_name: string
    notary_lastname: string
    notary_second_lastname: string
    notary_protocol_number: string
    notary_protocol_date: string
}

export const Step4: FC<Props> = ({ formik, representativesTypes, segment }) => {
    let component = <RepresentativeA formik={formik} segment={segment} />

    if (formik.values.representatives[0]?.representative_position) {
        if (
            representativesTypes[
                formik.values.representatives[0].representative_position
            ]?.type === 'B'
        ) {
            component = (
                <RepresentativeB
                    formik={formik}
                    representativesTypes={representativesTypes}
                    segment={segment}
                />
            )
        }
        if (
            representativesTypes[
                formik.values.representatives[0].representative_position
            ]?.type === 'C'
        ) {
            component = <RepresentativeC formik={formik} segment={segment} />
        }

        if (
            segment === 1 &&
            representativesTypes[
                formik.values.representatives[0].representative_position
            ]?.type === 'A'
        ) {
            component = <RepresentativeC formik={formik} segment={segment} />
        }
    }

    return (
        <Box w="full" mt={4} color="black">
            <Box
                w="90%"
                display="flex"
                justifyContent="space-between"
                fontSize={13}
                fontWeight={400}>
                <Box display="flex">
                    <Text mr={4}>Cliente</Text>
                    <Text fontWeight={700}>
                        {formik.values.client_type === 'a'
                            ? 'Autónomo'
                            : 'Empresa'}
                    </Text>
                </Box>
                {formik.values.client_type === 's' && (
                    <Box display="flex">
                        <Text mr={4}>Representante</Text>
                        <Text fontWeight={700}>
                            {
                                representativesTypes[
                                    formik.values.representatives[0]
                                        .representative_position
                                ].type
                            }
                        </Text>
                    </Box>
                )}
            </Box>

            <Box my={6} w="100%">
                {formik.values.client_type === 'a' ? (
                    <>
                        {segment !== 1 ? (
                            <Box
                                h="60px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center">
                                <Text fontSize={14} fontWeight={400}>
                                    No tiene representante
                                </Text>
                            </Box>
                        ) : (
                            <RepresentativeA
                                formik={formik}
                                segment={segment}
                            />
                        )}
                    </>
                ) : (
                    <>{component}</>
                )}
            </Box>
        </Box>
    )
}
