import React, { FC, useState } from 'react'

import { useFormik } from 'formik'
import { useSWRConfig } from 'swr'
import {
    Button,
    Divider,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text,
    ModalHeader,
    Box,
    FormControl,
    FormLabel,
    Select,
    FormErrorMessage,
    Center,
    useToast,
    RadioGroup,
    Stack,
    Radio,
    Alert,
    AlertIcon,
} from '@chakra-ui/react'

import { colors } from '@/utils/const'
import { useClient } from '@/hooks/client'
import { Opportunity, UserProfile } from '@/interfaces'
import { useOpportunity } from '@/hooks/opportunities'
import { CloseIcon } from '@chakra-ui/icons'

interface Props {
    opportunity: Opportunity
    userProfile: UserProfile
    isOpen: boolean
    onClose: () => void
}

export const EmployeesModal: FC<Props> = ({
    opportunity,
    userProfile,
    isOpen,
    onClose,
}) => {
    const { mutate } = useSWRConfig()
    const { updateOpportunity, setStatusOpportunity } = useOpportunity({
        id: opportunity?.id,
    })
    const { updateClient } = useClient({
        client_id: opportunity?.client?.id,
    })
    const [isLoading, setIsLoading] = useState(false)
    const [value, setValue] = useState(
        userProfile?.data?.civil_society ? '1' : '0',
    )
    const toast = useToast()

    const formik = useFormik({
        initialValues: {
            employees_number: userProfile?.data?.segment || '1',
            civil_society: userProfile?.data?.civil_society || false,
        },
        onSubmit: async values => {
            setIsLoading(true)

            try {
                if (values.civil_society) {
                    await updateClient({
                        client_id: opportunity?.client?.id,
                        data: {
                            civil_society: values.civil_society,
                        },
                    })

                    await setStatusOpportunity({
                        opportunity_id: opportunity?.id,
                        status: 'qualified',
                    })
                } else {
                    await updateClient({
                        client_id: opportunity?.client?.id,
                        data: {
                            employees_number: parseInt(values.employees_number),
                            civil_society: values.civil_society,
                        },
                    })

                    await updateOpportunity({
                        opportunity_id: opportunity?.id,
                        data: {
                            segment: parseInt(values.employees_number),
                        },
                    })
                }

                mutate(`/api/opportunity/${opportunity?.id}`)
                onClose()
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })

                formik.resetForm()
            }
            setIsLoading(false)
        },
    })

    const handleChange = e => {
        if (e === '1') {
            formik.setFieldValue('civil_society', true)
            setValue('1')
        } else {
            formik.setFieldValue('civil_society', false)
            setValue('0')
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <form onSubmit={formik.handleSubmit}>
                <ModalContent>
                    <ModalHeader
                        color="#4F4F4F"
                        fontSize={13}
                        fontWeight={400}
                        pt={4}
                        pb={1}>
                        Empleados
                    </ModalHeader>
                    <Divider />
                    <ModalBody mb={4} px={0}>
                        <Box
                            px={8}
                            py={2}
                            color="#000"
                            fontSize={13}
                            fontWeight={500}>
                            <Text mb={4}>
                                El plazo para contabilizar la plantilla media de
                                trabajadores del Régimen General (Seguridad
                                Social) será el correspondiente a los últimos 12
                                meses anteriores a la fecha de solicitud de la
                                ayuda.
                            </Text>
                            <Text mb={4}>
                                El número de empleados se calculará teniendo en
                                cuenta los datos de trabajadores existentes en
                                el Régimen General de la Seguridad Social y en
                                el Régimen Especial de Trabajadores Autónomos
                                (autónomos colaboradores y autónomos
                                societarios).
                            </Text>
                            <Center>
                                <FormControl
                                    w="50%"
                                    mb={4}
                                    isInvalid={
                                        formik.errors.employees_number &&
                                        formik.touched.employees_number
                                    }>
                                    <FormLabel fontSize={13} fontWeight={700}>
                                        Empleados
                                    </FormLabel>
                                    <Select
                                        id="employees_number"
                                        rounded="4px"
                                        size="xs"
                                        onChange={formik.handleChange}
                                        value={formik.values.employees_number}>
                                        <option value={1}>
                                            Segmento I: 10 a 49 empleados
                                        </option>
                                        <option value={2}>
                                            Segmento II: 3 a 9 empleados
                                        </option>
                                        <option value={3}>
                                            Segmento III: 0 a 2 empleados
                                        </option>
                                    </Select>
                                    <FormErrorMessage>
                                        {formik.errors.employees_number}
                                    </FormErrorMessage>
                                </FormControl>
                            </Center>
                        </Box>

                        <ModalHeader
                            color="#4F4F4F"
                            fontSize={13}
                            fontWeight={400}
                            pt={4}
                            pb={1}>
                            Sociedad Civil
                        </ModalHeader>
                        <Divider />

                        <Box
                            px={8}
                            py={4}
                            color="#000"
                            display="flex"
                            justifyContent="center"
                            fontSize={13}
                            fontWeight={500}>
                            <FormLabel fontSize={13} fontWeight={700}>
                                ¿Es esta oportunidad una sociedad civil (S.C.)?
                            </FormLabel>

                            <RadioGroup
                                id="civil_society"
                                onChange={handleChange}
                                value={value}>
                                <Stack direction="row">
                                    <Radio value="1">Sí</Radio>
                                    <Radio value="0">No</Radio>
                                </Stack>
                            </RadioGroup>
                        </Box>

                        <Box
                            px={8}
                            color="#000"
                            display="flex"
                            fontSize={13}
                            fontWeight={500}>
                            <Alert status="info">
                                <AlertIcon />
                                <Text>
                                    Si tu cliente es <b>Autónomo</b>, Sociedad
                                    Limitada (<b>S.L.</b>), Sociedad Limitada
                                    Unipersonal (<b>S.L.U.</b>), Sociedad
                                    Anónima (<b>S.A.</b>), en este caso{' '}
                                    <b>la respuesta es NO</b>
                                </Text>
                            </Alert>
                        </Box>

                        {formik.values.civil_society && (
                            <Box
                                my={2}
                                px={8}
                                color="#000"
                                display="flex"
                                fontSize={13}
                                fontWeight={500}>
                                <Alert status="error">
                                    <CloseIcon
                                        bg={'red'}
                                        color="white"
                                        boxSize="20px"
                                        padding={1}
                                        rounded="full"
                                    />
                                    <Text ml={2}>
                                        Para <b>Sociedades Civiles</b> no se
                                        está disponible aún la convocatoria
                                    </Text>
                                </Alert>
                            </Box>
                        )}
                    </ModalBody>

                    <Divider />

                    <ModalFooter justifyContent="center" py={4}>
                        <Button
                            bg="white"
                            border="1px"
                            borderColor={colors.secondary}
                            color={colors.secondary}
                            fontSize={14}
                            fontWeight={400}
                            mr={12}
                            h="32px"
                            w={119}
                            onClick={onClose}
                            _active={{
                                transform: 'scale(0.98)',
                            }}
                            _hover={{}}>
                            Atrás
                        </Button>

                        <Button
                            bg={colors.secondary}
                            border="1px"
                            color="white"
                            isLoading={isLoading}
                            fontSize={12}
                            fontWeight={400}
                            type="submit"
                            h="32px"
                            w={119}
                            _active={{
                                transform: 'scale(0.98)',
                            }}
                            _hover={{}}>
                            Continuar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    )
}
