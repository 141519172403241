// @ts-nocheck
import React, { FC, useState, useEffect } from 'react'

import { useFormik } from 'formik'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
    Box,
    Button,
    chakra,
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    HStack,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Spinner,
    Text,
    useToast,
    Image,
    useRadio,
    useRadioGroup,
    useDisclosure,
} from '@chakra-ui/react'

import { useOpportunities, useOpportunity } from '@/hooks/opportunities'
import { CloseIcon } from '@chakra-ui/icons'
import { Store2Line } from '../../icons'
import { colors, validateCIF, validateDNI } from '@/utils/const'
import {
    getProfilesQuestions,
    useClient,
    useClients,
    verifyDNI,
} from '@/hooks/client'
import { AddLicence } from '../AddLicence'
import { AddCollaborator } from '../AddCollaborator'
import { EProductManagment, ESolutionManagment } from '@/constants/solutions'
import { Distributor } from '@/interfaces'
import { HAS_PRINTER_OPTIONS } from '@/constants/client'
import { FastOpportunitySchema } from '@/schemes/FastOpportunityScheme'
import { SelectPrinterRadio } from '@/components/utils/SelectPrinterRadio'
import DNIConfirmationAlertModal from './DNIConfirmationAlertModal'
import { useDNIConfirmation } from '@/hooks/use-dni-confirmation'
import { ANTIQUITY_OPTIONS } from '@/constants/antiquity'

interface Props {
    user_id: number
    userName: string
    isOpen: boolean
    onClose: () => void
}

export const FastOpportunityModal: FC<Props> = ({
    user_id,
    userName,
    isOpen,
    onClose,
}) => {
    const { createOpportunity, getDistributors } = useOpportunities({})
    const { setStatusOpportunity } = useOpportunity({ id: null })
    const { sendDocuments } = useClient({
        client_id: null,
    })
    const { createClient } = useClients({})
    const [isLoading, setIsLoading] = useState(false)
    const [distributors, setDistributors] = useState<Distributor[]>([])
    const [profileId, setProfileId] = useState(null)
    const [questions, setQuestions] = useState(null)
    const [isEmailsDuplicated, setIsEmailsDuplicated] = useState(false)

    const toast = useToast()

    const formik = useFormik({
        initialValues: {
            title: '',
            titleNumber: '',
            segment: 3,
            distributor: 0,
            contact_name: '',
            phone: '',
            email: '',

            client_type: 'a',
            name: '',
            lastName: '',
            dni: '',
            postal_code: '',
            town: '',
            province: '',
            home_address: '',
            antiquity: '',
            enterprise_created_date: '',
            has_printer: HAS_PRINTER_OPTIONS.yes.value,

            representatives: [
                {
                    name: '',
                    lastname: '',
                    second_lastname: '',
                    dni: '',
                    position: 'adm_unique',
                },
            ],

            licenses: [],
            collaborators: [],
        },

        // adm_unique
        validationSchema: FastOpportunitySchema,
        onSubmit: () => {},
    })

    const { getRootProps, getRadioProps } = useRadioGroup({
        defaultValue: formik.values.has_printer,
        onChange: value => {
            formik.setFieldValue('has_printer', value)
        },
    })
    const group = getRootProps()

    useEffect(() => {
        getDistributors()
            .then(async values => {
                setDistributors(values)
                handleChangeDistributor(values[0]?.id)
            })
            .catch(err => onClose())

        const fetchProfilesQuestions = async () => {
            const profiles = await getProfilesQuestions()

            if (profiles.length > 0) {
                setProfileId(profiles[0].id)
                setQuestions(profiles[0].data)
            }
        }

        fetchProfilesQuestions()
    }, [])

    const handleChangeDistributor = id => {
        formik.setFieldValue('distributor', parseInt(id))
    }

    const sendClient = React.useCallback(async () => {
        setIsLoading(true)
        const values = formik.values
        const distributor_id =
            values.distributor !== 0 ? values.distributor : null

        try {
            const opportunity = await createOpportunity({
                user_id,
                name: `Taxi ${values.title} ${values.titleNumber}`,
                segment: values.segment,
                amount: values.segment == 2 ? '6000' : '2000',
                distributor_id,
                contact_name: values.contact_name,
                contact_hours: '',
                phone: values.phone ? `+${values.phone}` : null,
                email: values.email,
                init_date: '',
                end_date: '',
                licenses: values.licenses
                    ? JSON.stringify(values.licenses)
                    : null,
                opportunity_version: 'v3',
            })

            const client = await createClient({
                customerData: {
                    opportunity_id: opportunity.data.id,
                    preliminary_agreement: false,
                    employees_number: values.segment || 3,
                    name: `${formik.values.name} ${formik.values.lastName}`,
                    representative_volunteer_id: 4,
                    sale_manager_id: user_id,
                    release_date: new Date().toJSON().split('T')[0],
                    client_type: formik.values.client_type,
                    dni: formik.values.dni.toUpperCase(),
                    phone: `+${formik.values.phone}`,
                    email: formik.values.email,
                    home_address: formik.values.home_address,
                    postal_code: formik.values.postal_code,
                    province: formik.values.province,
                    town: formik.values.town,
                    activity: 'Taxi',
                    cnae: 'H',
                    iae: '721.2',
                    antiquity: formik.values.antiquity,
                    enterprise_created_date:
                        formik.values.enterprise_created_date,
                    representatives:
                        values.client_type === 's'
                            ? values.representatives
                            : [],
                    products: [
                        {
                            solution_managment_id:
                                ESolutionManagment.proccess_managment,
                            product_managment_id: EProductManagment.flesip,
                            package_managment_id: distributors.find(
                                item => item.id === distributor_id,
                            ).profile?.package,
                            price: '2000',
                        },
                    ],
                    has_printer:
                        values.has_printer === HAS_PRINTER_OPTIONS.yes.value
                            ? true
                            : false,
                    question_test: questions ? JSON.stringify(questions) : '',
                    question_profile_id: profileId,
                    collaborators: values.collaborators
                        ? JSON.stringify(values.collaborators)
                        : '',
                },
            })

            await setStatusOpportunity({
                opportunity_id: opportunity.data.id,
                status: 'repre_vol',
            })

            await sendDocuments({
                client_id: client.data.id,
            })

            toast({
                title: 'Éxito',
                description: 'Oportunidad creada con éxito',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
            formik.resetForm()

            if (distributors.length > 0) {
                formik.setFieldValue('distributor', distributors[0].id)
            }

            onClose()
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }

        setIsLoading(false)
    }, [formik.values])

    const {
        isOpenDNIConfirmation,
        onToggleDNIConfirmation,
        DNIConfirmation,
        setDNIConfirmation,
        onContinue: onContinueDNIConfirmation,
    } = useDNIConfirmation(sendClient)

    const handleSubmit = async () => {
        const validations = await formik.validateForm(formik.values)
        const values = formik.values

        if (
            validations.title ||
            validations.titleNumber ||
            validations.antiquity
        ) {
            formik.setErrors({
                title: validations.title,
                titleNumber: validations.titleNumber,
                antiquity: validations.antiquity,
            })
            formik.setTouched({
                title: true,
                titleNumber: true,
                antiquity: true,
            })

            return null
        }

        if (validations.licenses) {
            if (validations.licenses?.length > 0) {
                // @ts-ignore
                validations.licenses.forEach((element, index) => {
                    if (element?.license) {
                        // @ts-ignore
                        formik.setFieldError(
                            `licenses[${index}].license`,
                            validations.licenses[index].license,
                        )
                        formik.setFieldTouched(
                            `licenses[${index}].license`,
                            true,
                        )
                    }
                })
            }

            return null
        }

        if (values?.licenses?.length > 0) {
            const emailsArray = values.licenses
                .map(item => item.email)
                .concat(values.email)
            const emailsSet = new Set(emailsArray)

            if (emailsSet.size !== emailsArray.length) {
                setIsEmailsDuplicated(true)
                return null
            }
        }

        setIsEmailsDuplicated(false)

        if (validations.collaborators) {
            if (validations.collaborators?.length > 0) {
                // @ts-ignore
                validations.collaborators.forEach((element, index) => {
                    if (element?.name || element?.lastName || element?.dni) {
                        // @ts-ignore
                        formik.setFieldError(
                            `collaborators[${index}].name`,
                            validations.collaborators[index].name,
                        )
                        // @ts-ignore
                        formik.setFieldError(
                            `collaborators[${index}].lastName`,
                            validations.collaborators[index].lastName,
                        )
                        // @ts-ignore
                        formik.setFieldError(
                            `collaborators[${index}].dni`,
                            validations.collaborators[index].dni,
                        )
                        formik.setFieldTouched(
                            `collaborators[${index}].name`,
                            true,
                        )
                        formik.setFieldTouched(
                            `collaborators[${index}].lastName`,
                            true,
                        )
                        formik.setFieldTouched(
                            `collaborators[${index}].dni`,
                            true,
                        )
                    }
                })
            }

            return null
        } else {
            let isValidNIF = true
            values.collaborators.forEach(async (element, index) => {
                if (!validateDNI(element.dni)) {
                    isValidNIF = false
                    await formik.setFieldTouched(
                        `collaborators[${index}].dni`,
                        true,
                    )
                    formik.setFieldError(
                        `collaborators[${index}].dni`,
                        'El NIF es inválido',
                    )
                }
            })

            if (!isValidNIF) return null
        }

        if (
            values.antiquity === 'Menos de 6 meses' &&
            values.enterprise_created_date === ''
        ) {
            await formik.setFieldTouched('enterprise_created_date', true)
            formik.setFieldError(
                'enterprise_created_date',
                'Si la empresa es menor a 6 meses, debe indicar la fecha de creación',
            )

            return null
        }

        if (values.client_type === 'a') {
            if (
                validations.name ||
                validations.dni ||
                validations.home_address ||
                validations.postal_code ||
                validations.town ||
                validations.province ||
                validations.lastName ||
                validations.email ||
                validations.phone
            ) {
                formik.setErrors({
                    name: validations.name,
                    lastName: validations.lastName,
                    dni: validations.dni,
                    home_address: validations.home_address,
                    postal_code: validations.postal_code,
                    town: validations.town,
                    province: validations.province,
                    phone: validations.phone,
                    email: validations.email,
                })
                formik.setTouched({
                    name: true,
                    lastName: true,
                    dni: true,
                    home_address: true,
                    postal_code: true,
                    town: true,
                    province: true,
                    email: true,
                    phone: true,
                })

                return null
            }

            if (!validateDNI(values.dni)) {
                await formik.setFieldTouched(`dni`, true)
                formik.setFieldError(`dni`, 'El NIF es inválido')

                return null
            }

            if (DNIConfirmation) {
                try {
                    const data = await verifyDNI({
                        dni: formik.values.dni,
                    })
                } catch (error) {
                    onToggleDNIConfirmation()
                    return
                }
            }

            sendClient()
        } else {
            if (
                validations.name ||
                validations.dni ||
                validations.home_address ||
                validations.postal_code ||
                validations.town ||
                validations.province ||
                validations.email ||
                validations.phone
            ) {
                formik.setErrors({
                    name: validations.name,
                    dni: validations.dni,
                    home_address: validations.home_address,
                    postal_code: validations.postal_code,
                    town: validations.town,
                    province: validations.province,
                    phone: validations.phone,
                    email: validations.email,
                })
                formik.setTouched({
                    name: true,
                    lastName: true,
                    dni: true,
                    home_address: true,
                    postal_code: true,
                    town: true,
                    province: true,
                    email: true,
                    phone: true,
                })

                return null
            }

            if (validations.representatives?.length > 0) {
                const representativeValues = validations.representatives[0]

                // @ts-ignore
                if (
                    representativeValues.dni ||
                    representativeValues.lastname ||
                    representativeValues.name
                ) {
                    // @ts-ignore
                    formik.setFieldError(
                        'representatives[0].name',
                        validations.representatives[0].name,
                    )
                    // @ts-ignore
                    formik.setFieldError(
                        'representatives[0].lastname',
                        validations.representatives[0].lastname,
                    )
                    // @ts-ignore
                    formik.setFieldError(
                        'representatives[0].dni',
                        validations.representatives[0].dni,
                    )
                    formik.setFieldTouched('representatives[0].name', true)
                    formik.setFieldTouched('representatives[0].lastname', true)
                    formik.setFieldTouched('representatives[0].dni', true)

                    return null
                }
            }

            if (!validateDNI(values.representatives[0].dni)) {
                await formik.setFieldTouched(`representatives[0].dni`, true)
                formik.setFieldError(
                    `representatives[0].dni`,
                    'El NIF es inválido',
                )

                return null
            }

            if (!validateCIF(values.dni)) {
                await formik.setFieldTouched(`dni`, true)
                formik.setFieldError(`dni`, 'El CIF es inválido')

                return null
            }

            if (DNIConfirmation) {
                try {
                    const data = await verifyDNI({
                        dni: formik.values.dni,
                    })
                } catch (error) {
                    onToggleDNIConfirmation()
                    return
                }
            }

            sendClient()
        }
    }

    function handleCloseModal() {
        onClose()
        formik.resetForm()
        setDNIConfirmation(true)
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={handleCloseModal} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <form onSubmit={formik.handleSubmit}>
                        <ModalHeader
                            px={4}
                            py={2}
                            backgroundColor="primary-soft-hover">
                            <Grid
                                backgroundColor="transparent"
                                templateColumns="100px 1fr 1fr 24px"
                                gap={4}>
                                <GridItem w="100%">
                                    <Image
                                        src="/images/FastOp.png"
                                        alt="logo"
                                    />
                                </GridItem>
                                <GridItem
                                    alignItems="center"
                                    display="flex"
                                    fontSize={16}
                                    fontWeight={700}
                                    w="100%">
                                    Oportunidad rápida
                                </GridItem>
                                <GridItem
                                    w="100%"
                                    justifyContent="end"
                                    alignItems="center"
                                    display="flex">
                                    <Box mr={2}>
                                        <Store2Line />
                                    </Box>
                                    <Text ml={3} fontSize={13} fontWeight={500}>
                                        {userName}
                                    </Text>
                                </GridItem>
                                <GridItem w="100%">
                                    <IconButton
                                        fontSize={'10px'}
                                        size="xs"
                                        icon={<CloseIcon />}
                                        onClick={handleCloseModal}
                                        aria-label=""
                                    />
                                </GridItem>
                            </Grid>
                        </ModalHeader>

                        <Divider />

                        <ModalBody>
                            {isLoading ? (
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    h="300px"
                                    justifyContent="center">
                                    <Spinner
                                        thickness="4px"
                                        speed="0.65s"
                                        emptyColor="gray.200"
                                        color="blue.500"
                                        size="xl"
                                    />
                                </Box>
                            ) : (
                                <>
                                    <FormLabel fontSize={13} fontWeight={700}>
                                        Nombre de la oportunidad
                                        <span style={{ color: '#FD7C04' }}>
                                            *
                                        </span>
                                    </FormLabel>

                                    <HStack mb={4}>
                                        <FormControl
                                            isInvalid={
                                                formik.errors.title &&
                                                formik.touched.title
                                            }>
                                            <HStack spacing={4}>
                                                <Text fontSize={12}>Taxi</Text>
                                                <Input
                                                    id="title"
                                                    placeholder="localidad"
                                                    rounded="4px"
                                                    width={200}
                                                    size="xs"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    value={formik.values.title}
                                                />
                                            </HStack>
                                            <FormErrorMessage>
                                                {formik.errors.title}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <FormControl
                                            isInvalid={
                                                formik.errors.titleNumber &&
                                                formik.touched.titleNumber
                                            }>
                                            <HStack spacing={4}>
                                                <Text fontSize={12}>Nº</Text>
                                                <Input
                                                    id="titleNumber"
                                                    placeholder="Nº de licencia"
                                                    rounded="4px"
                                                    width={100}
                                                    size="xs"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    value={
                                                        formik.values
                                                            .titleNumber
                                                    }
                                                />
                                            </HStack>
                                            <FormErrorMessage>
                                                {formik.errors.titleNumber}
                                            </FormErrorMessage>
                                        </FormControl>
                                    </HStack>

                                    {formik.values.distributor &&
                                    distributors.find(
                                        item =>
                                            item.id ===
                                            formik.values.distributor,
                                    )?.profile?.various_licenses ? (
                                        <AddLicence
                                            formik={formik}
                                            isEmailDuplicated={
                                                isEmailsDuplicated
                                            }
                                        />
                                    ) : null}

                                    <Divider />

                                    <FormControl
                                        mt={2}
                                        mb={4}
                                        isInvalid={
                                            formik.errors.segment &&
                                            formik.touched.segment
                                        }>
                                        <FormLabel
                                            fontSize={13}
                                            fontWeight={700}>
                                            Segmento
                                        </FormLabel>
                                        <Select
                                            id="segment"
                                            rounded="4px"
                                            size="xs"
                                            w="255px"
                                            onChange={formik.handleChange}
                                            value={formik.values.segment}>
                                            <option value={1} disabled={true}>
                                                1 (10 a menos de 50 empleados)
                                            </option>
                                            <option value={2}>
                                                2 (3 a menos de 10 empleados)
                                            </option>
                                            <option value={3}>
                                                3 (0 a menos de 3 empleados)
                                            </option>
                                        </Select>
                                        <FormErrorMessage>
                                            {formik.errors.segment}
                                        </FormErrorMessage>
                                    </FormControl>

                                    <Grid
                                        templateColumns="1fr 1fr"
                                        gap={4}
                                        mb={4}>
                                        <FormControl
                                            isInvalid={
                                                formik.errors.client_type &&
                                                formik.touched.client_type
                                            }>
                                            <FormLabel
                                                fontSize={13}
                                                fontWeight={700}>
                                                Tipo de cliente
                                            </FormLabel>
                                            <Select
                                                id="client_type"
                                                rounded="4px"
                                                size="xs"
                                                onChange={formik.handleChange}
                                                value={
                                                    formik.values.client_type
                                                }>
                                                <option value={'a'}>
                                                    Autónomo
                                                </option>
                                                <option value={'s'}>
                                                    Empresa
                                                </option>
                                            </Select>
                                            <FormErrorMessage>
                                                {formik.errors.client_type}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <FormControl
                                            isInvalid={
                                                formik.errors.distributor &&
                                                formik.touched.distributor
                                            }>
                                            <FormLabel
                                                fontSize={13}
                                                fontWeight={700}>
                                                Distribuidor
                                            </FormLabel>
                                            <Select
                                                id="distributor"
                                                rounded="4px"
                                                size="xs"
                                                placeholder="Distribuidor"
                                                value={
                                                    formik.values.distributor
                                                }
                                                onChange={e =>
                                                    handleChangeDistributor(
                                                        e.target.value,
                                                    )
                                                }>
                                                {distributors.map(
                                                    (item, index) => (
                                                        <option
                                                            key={index}
                                                            value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ),
                                                )}
                                            </Select>
                                        </FormControl>
                                        <FormErrorMessage>
                                            {formik.errors.distributor}
                                        </FormErrorMessage>
                                    </Grid>

                                    <Grid templateColumns="1fr 1fr" gap={4}>
                                        <FormControl
                                            isInvalid={
                                                formik.errors.name &&
                                                formik.touched.name
                                            }>
                                            <FormLabel
                                                fontSize={13}
                                                fontWeight={700}>
                                                {formik.values.client_type ===
                                                's'
                                                    ? 'Nombre de empresa'
                                                    : 'Nombre'}
                                                <chakra.span
                                                    style={{
                                                        color: '#FD7C04',
                                                    }}>
                                                    *
                                                </chakra.span>
                                            </FormLabel>
                                            <Input
                                                id="name"
                                                placeholder={
                                                    formik.values
                                                        .client_type === 's'
                                                        ? 'Nombre de empresa'
                                                        : 'Nombre'
                                                }
                                                rounded="4px"
                                                size="xs"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                            />
                                            <FormErrorMessage>
                                                {formik.errors.name}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <GridItem w="100%">
                                            {formik.values.client_type ===
                                                'a' && (
                                                <FormControl
                                                    isInvalid={
                                                        formik.errors
                                                            .lastName &&
                                                        formik.touched.lastName
                                                    }>
                                                    <FormLabel
                                                        fontSize={13}
                                                        fontWeight={700}>
                                                        Apellidos
                                                        <chakra.span
                                                            style={{
                                                                color:
                                                                    '#FD7C04',
                                                            }}>
                                                            *
                                                        </chakra.span>
                                                    </FormLabel>
                                                    <Input
                                                        id="lastName"
                                                        placeholder="Apellidos"
                                                        rounded="4px"
                                                        size="xs"
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        onBlur={
                                                            formik.handleBlur
                                                        }
                                                        value={
                                                            formik.values
                                                                .lastName
                                                        }
                                                    />
                                                    <FormErrorMessage>
                                                        {formik.errors.lastName}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </GridItem>

                                        <FormControl
                                            isInvalid={
                                                formik.errors.dni &&
                                                formik.touched.dni
                                            }>
                                            <FormLabel
                                                fontSize={13}
                                                fontWeight={700}>
                                                {formik.values.client_type ===
                                                's'
                                                    ? 'CIF'
                                                    : 'NIF'}
                                                <chakra.span
                                                    style={{
                                                        color: '#FD7C04',
                                                    }}>
                                                    {' '}
                                                    *
                                                </chakra.span>
                                            </FormLabel>
                                            <Input
                                                id="dni"
                                                placeholder={
                                                    formik.values
                                                        .client_type === 's'
                                                        ? 'CIF'
                                                        : 'NIF'
                                                }
                                                rounded="4px"
                                                size="xs"
                                                onChange={formik.handleChange}
                                                value={formik.values.dni}
                                                onBlur={formik.handleBlur}
                                            />
                                            <FormErrorMessage>
                                                {formik.errors.dni}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <FormControl
                                            isInvalid={
                                                formik.errors.email &&
                                                formik.touched.email
                                            }>
                                            <FormLabel
                                                fontSize={13}
                                                fontWeight={700}>
                                                Email
                                            </FormLabel>
                                            <Input
                                                id="email"
                                                bg="white"
                                                placeholder="Email"
                                                rounded="4px"
                                                size="xs"
                                                type="email"
                                                onChange={formik.handleChange}
                                                value={formik.values.email}
                                            />
                                            <FormErrorMessage>
                                                {formik.errors.email}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <FormControl
                                            isInvalid={
                                                formik.errors.phone &&
                                                formik.touched.phone
                                            }>
                                            <FormLabel
                                                fontSize={13}
                                                fontWeight={700}>
                                                Teléfono{' '}
                                            </FormLabel>
                                            <PhoneInput
                                                inputStyle={{
                                                    height: '25px',
                                                    width: '100%',
                                                }}
                                                country={'es'}
                                                value={formik.values.phone}
                                                onChange={phone =>
                                                    formik.setFieldValue(
                                                        'phone',
                                                        phone,
                                                    )
                                                }
                                            />
                                            <FormErrorMessage>
                                                {formik.errors.phone}
                                            </FormErrorMessage>
                                        </FormControl>

                                        {formik.values.client_type === 's' && (
                                            <>
                                                <FormControl
                                                    isInvalid={
                                                        Array.isArray(
                                                            formik.errors
                                                                .representatives,
                                                        ) &&
                                                        // @ts-ignore
                                                        formik.errors
                                                            ?.representatives[0]
                                                            ?.name &&
                                                        Array.isArray(
                                                            formik.touched
                                                                .representatives,
                                                        ) &&
                                                        formik.touched
                                                            .representatives[0]
                                                            ?.name
                                                    }>
                                                    <FormLabel
                                                        fontSize={13}
                                                        fontWeight={700}>
                                                        Nombre del representante{' '}
                                                        <chakra.span
                                                            style={{
                                                                color:
                                                                    '#FD7C04',
                                                            }}>
                                                            *
                                                        </chakra.span>
                                                    </FormLabel>
                                                    <Input
                                                        id="representatives[0].name"
                                                        placeholder="Nombre"
                                                        rounded="4px"
                                                        size="xs"
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        onBlur={
                                                            formik.handleBlur
                                                        }
                                                        value={
                                                            formik.values
                                                                .representatives[0]
                                                                .name
                                                        }
                                                    />
                                                    {Array.isArray(
                                                        formik.errors
                                                            .representatives,
                                                    ) && (
                                                        <FormErrorMessage>
                                                            {formik.errors.representatives.map(
                                                                error =>
                                                                    error.name,
                                                            )}
                                                        </FormErrorMessage>
                                                    )}
                                                </FormControl>
                                                <FormControl
                                                    isInvalid={
                                                        Array.isArray(
                                                            formik.errors
                                                                .representatives,
                                                        ) &&
                                                        // @ts-ignore
                                                        formik.errors
                                                            ?.representatives[0]
                                                            ?.lastname &&
                                                        Array.isArray(
                                                            formik.touched
                                                                .representatives,
                                                        ) &&
                                                        formik.touched
                                                            .representatives[0]
                                                            ?.lastname
                                                    }>
                                                    <FormLabel
                                                        fontSize={13}
                                                        fontWeight={700}>
                                                        Primer apellido del
                                                        representante{' '}
                                                        <chakra.span
                                                            style={{
                                                                color:
                                                                    '#FD7C04',
                                                            }}>
                                                            *
                                                        </chakra.span>
                                                    </FormLabel>
                                                    <Input
                                                        id="representatives[0].lastname"
                                                        placeholder="Primer apellido"
                                                        rounded="4px"
                                                        size="xs"
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        onBlur={
                                                            formik.handleBlur
                                                        }
                                                        value={
                                                            formik.values
                                                                .representatives[0]
                                                                .lastname
                                                        }
                                                    />
                                                    {Array.isArray(
                                                        formik.errors
                                                            .representatives,
                                                    ) && (
                                                        <FormErrorMessage>
                                                            {formik.errors.representatives.map(
                                                                error =>
                                                                    error.lastname,
                                                            )}
                                                        </FormErrorMessage>
                                                    )}
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel
                                                        fontSize={13}
                                                        fontWeight={700}>
                                                        Segundo apellido del
                                                        representante
                                                    </FormLabel>
                                                    <Input
                                                        id="representatives[0].second_lastname"
                                                        placeholder="Segundo apellido"
                                                        rounded="4px"
                                                        size="xs"
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        onBlur={
                                                            formik.handleBlur
                                                        }
                                                        value={
                                                            formik.values
                                                                .representatives[0]
                                                                .second_lastname ||
                                                            ''
                                                        }
                                                    />
                                                </FormControl>
                                                <FormControl
                                                    isInvalid={
                                                        Array.isArray(
                                                            formik.errors
                                                                .representatives,
                                                        ) &&
                                                        // @ts-ignore
                                                        formik.errors
                                                            ?.representatives[0]
                                                            ?.dni &&
                                                        Array.isArray(
                                                            formik.touched
                                                                .representatives,
                                                        ) &&
                                                        formik.touched
                                                            .representatives[0]
                                                            ?.dni
                                                    }>
                                                    <FormLabel
                                                        fontSize={13}
                                                        fontWeight={700}>
                                                        NIF del representante{' '}
                                                        <chakra.span
                                                            style={{
                                                                color:
                                                                    '#FD7C04',
                                                            }}>
                                                            {' '}
                                                            *
                                                        </chakra.span>
                                                    </FormLabel>
                                                    <Input
                                                        id="representatives[0].dni"
                                                        placeholder="NIF"
                                                        rounded="4px"
                                                        size="xs"
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        value={
                                                            formik.values
                                                                .representatives[0]
                                                                .dni
                                                        }
                                                    />
                                                    {Array.isArray(
                                                        formik.errors
                                                            .representatives,
                                                    ) && (
                                                        <FormErrorMessage>
                                                            {formik.errors.representatives.map(
                                                                error =>
                                                                    error.dni,
                                                            )}
                                                        </FormErrorMessage>
                                                    )}
                                                </FormControl>
                                            </>
                                        )}

                                        <FormControl
                                            isInvalid={
                                                formik.errors.home_address &&
                                                formik.touched.home_address
                                            }>
                                            <FormLabel
                                                fontSize={13}
                                                fontWeight={700}>
                                                Domicilio
                                                <chakra.span
                                                    style={{
                                                        color: '#FD7C04',
                                                    }}>
                                                    {' '}
                                                    *
                                                </chakra.span>
                                            </FormLabel>
                                            <Input
                                                id="home_address"
                                                placeholder="Domicilio"
                                                rounded="4px"
                                                size="xs"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={
                                                    formik.values.home_address
                                                }
                                            />
                                            <FormErrorMessage>
                                                {formik.errors.home_address}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <FormControl
                                            isInvalid={
                                                formik.errors.postal_code &&
                                                formik.touched.postal_code
                                            }>
                                            <FormLabel
                                                fontSize={13}
                                                fontWeight={700}>
                                                CP
                                                <chakra.span
                                                    style={{
                                                        color: '#FD7C04',
                                                    }}>
                                                    {' '}
                                                    *
                                                </chakra.span>
                                            </FormLabel>
                                            <Input
                                                id="postal_code"
                                                placeholder="CP"
                                                rounded="4px"
                                                size="xs"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={
                                                    formik.values.postal_code
                                                }
                                            />
                                            <FormErrorMessage>
                                                {formik.errors.postal_code}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <FormControl
                                            isInvalid={
                                                formik.errors.town &&
                                                formik.touched.town
                                            }>
                                            <FormLabel
                                                fontSize={13}
                                                fontWeight={700}>
                                                Población
                                                <chakra.span
                                                    style={{
                                                        color: '#FD7C04',
                                                    }}>
                                                    {' '}
                                                    *
                                                </chakra.span>
                                            </FormLabel>
                                            <Input
                                                id="town"
                                                placeholder="Población"
                                                rounded="4px"
                                                size="xs"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.town}
                                            />
                                            <FormErrorMessage>
                                                {formik.errors.town}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <FormControl
                                            isInvalid={
                                                formik.errors.province &&
                                                formik.touched.province
                                            }>
                                            <FormLabel
                                                fontSize={13}
                                                fontWeight={700}>
                                                Provincia
                                                <chakra.span
                                                    style={{
                                                        color: '#FD7C04',
                                                    }}>
                                                    {' '}
                                                    *
                                                </chakra.span>
                                            </FormLabel>
                                            <Input
                                                id="province"
                                                placeholder="Provincia"
                                                rounded="4px"
                                                size="xs"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.province}
                                            />
                                            <FormErrorMessage>
                                                {formik.errors.province}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <FormControl
                                            isInvalid={
                                                formik.errors.antiquity &&
                                                formik.touched.antiquity
                                            }>
                                            <FormLabel
                                                fontSize={13}
                                                fontWeight={700}>
                                                Antigüedad Empresa{' '}
                                                <chakra.span
                                                    style={{
                                                        color: '#FD7C04',
                                                    }}>
                                                    *
                                                </chakra.span>
                                            </FormLabel>

                                            <Select
                                                id="antiquity"
                                                placeholder="Antigüedad Empresa"
                                                rounded="4px"
                                                size="xs"
                                                onChange={e => {
                                                    formik.setFieldValue(
                                                        'enterprise_created_date',
                                                        '',
                                                    )
                                                    formik.handleChange(e)
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.antiquity}>
                                                {ANTIQUITY_OPTIONS.map(item => (
                                                    <option
                                                        key={item}
                                                        value={item}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </Select>
                                            <FormErrorMessage>
                                                {formik.errors.antiquity}
                                            </FormErrorMessage>
                                        </FormControl>

                                        {formik.values.distributor ? (
                                            <FormControl
                                                hidden={
                                                    distributors.find(
                                                        item =>
                                                            item.id ===
                                                            formik.values
                                                                .distributor,
                                                    )?.profile?.has_printer
                                                }>
                                                <FormLabel
                                                    fontSize={13}
                                                    fontWeight={700}>
                                                    ¿Se debe entregar impresora?
                                                </FormLabel>

                                                <HStack {...group}>
                                                    {Object.values(
                                                        HAS_PRINTER_OPTIONS,
                                                    ).map(item => {
                                                        const radio = getRadioProps(
                                                            {
                                                                value:
                                                                    item.value,
                                                            },
                                                        )
                                                        return (
                                                            <SelectPrinterRadio
                                                                key={item.value}
                                                                value={
                                                                    item.value
                                                                }
                                                                {...radio}>
                                                                {item.label}
                                                            </SelectPrinterRadio>
                                                        )
                                                    })}
                                                </HStack>
                                            </FormControl>
                                        ) : null}

                                        {formik.values.antiquity ===
                                            'Menos de 6 meses' && (
                                            <FormControl
                                                isInvalid={
                                                    formik.errors
                                                        .enterprise_created_date &&
                                                    formik.touched
                                                        .enterprise_created_date
                                                }>
                                                <FormLabel
                                                    fontSize={13}
                                                    fontWeight={700}>
                                                    Fecha de creación de la
                                                    Empresa{' '}
                                                    <chakra.span
                                                        style={{
                                                            color: '#FD7C04',
                                                        }}>
                                                        *
                                                    </chakra.span>
                                                </FormLabel>

                                                <Input
                                                    id="enterprise_created_date"
                                                    rounded="4px"
                                                    size="xs"
                                                    type="date"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    value={
                                                        formik.values
                                                            .enterprise_created_date
                                                    }
                                                />
                                                <FormErrorMessage>
                                                    {
                                                        formik.errors
                                                            .enterprise_created_date
                                                    }
                                                </FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Grid>

                                    {/* <Divider mt={4} />

                                <AddCollaborator formik={formik} /> */}
                                </>
                            )}
                        </ModalBody>

                        <Divider />

                        <ModalFooter justifyContent="center">
                            <Button
                                bg="white"
                                border="1px"
                                borderColor={colors.secondary}
                                color={colors.secondary}
                                isDisabled={isLoading}
                                fontSize={12}
                                fontWeight={500}
                                h="32px"
                                mr={8}
                                w={120}
                                onClick={() => {
                                    formik.resetForm()
                                    setDNIConfirmation(true)
                                }}
                                _active={{
                                    transform: 'scale(0.98)',
                                }}
                                _hover={{}}>
                                Empezar de nuevo
                            </Button>
                            <Button
                                bg={colors.secondary}
                                border="1px"
                                borderColor={colors.secondary}
                                color="white"
                                isLoading={isLoading}
                                fontSize={12}
                                fontWeight={500}
                                h="32px"
                                type="button"
                                w={120}
                                onClick={handleSubmit}
                                _active={{
                                    transform: 'scale(0.98)',
                                }}
                                _hover={{}}>
                                Crear oportunidad
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>

            <DNIConfirmationAlertModal
                isOpen={isOpenDNIConfirmation}
                onClose={onToggleDNIConfirmation}
                onContinue={onContinueDNIConfirmation}
            />
        </>
    )
}
