//@ts-nocheck
import React, { FC, useState, useEffect } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Divider,
    Flex,
    Box,
    useToast,
} from '@chakra-ui/react'
import { Step, Steps, useSteps } from 'chakra-ui-steps'

import {
    getRepresentativeTypes,
    getProducts,
    useClient,
    getVolunteerRepresentatives,
    verifyDNI,
    verifyEmail,
} from '@/hooks/client'
import {
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    Step8,
    StepTest,
    Step1WPreliminary,
    StepInfoCompany,
} from '../qualifySteps'
import { colors, validateCIF, validateDNI } from '@/utils/const'
import { CircleIcon } from '../../icons'
import { Client, Opportunity } from '@/interfaces'
import { getSolutions } from '@/hooks/solutions'
import { HAS_PRINTER_OPTIONS } from '@/constants/client'

interface Props {
    opportunity: Opportunity
    segment: number
    client: Client
    isOpen: boolean
    onClose: () => void
}

const Schema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    lastName: Yup.string().required('El apellido es requerido'),
    phone: Yup.string()
        .min(10, 'Mínimo 10 caracteres')
        .max(14, 'Maximo 14 caracteres')
        .required('El teléfono del cliente es requerido'),
    email: Yup.string()
        .email('Correo inválido')
        .required('El correo es requerido'),
    employees_number: Yup.number(),
    preliminary_agreement: Yup.boolean(),
    client_type: Yup.string(),
    dni: Yup.string().required('El número de identificación es requerida'),
    postal_code: Yup.string().required('El código postal es requerido'),
    town: Yup.string().required('La población es requerida'),
    province: Yup.string().required('La provincia es requerida'),
    home_address: Yup.string().required('La dirección es requerida'),
    comment: Yup.string(),
    representative_volunteer_id: Yup.number(),
    activity: Yup.string().required('La actividad es requerida'),
    cnae: Yup.string(),
    iae: Yup.string(),
    antiquity: Yup.string(),
    enterprise_created_date: Yup.string().required(
        'Si la empresa es menor a 6 meses de antigüedad, debe indicar la fecha de creación',
    ),
    representatives: Yup.array()
        .of(
            Yup.object({
                name: Yup.string().required(
                    'El nombre del contacto es requerido',
                ),
                lastname: Yup.string().required(
                    'El primer apellido del contacto es requerido',
                ),
                second_lastname: Yup.string(),
                dni: Yup.string().required(
                    'El número de identificación del contacto es requerida',
                ),
                phone: Yup.string()
                    .min(9, 'Mínimo 9 caracteres')
                    .max(12, 'Maximo 12 caracteres')
                    .required('El teléfono del representante es requerido'),
                email: Yup.string()
                    .email('Correo inválido')
                    .required('El correo es requerido'),
                representative_position: Yup.string(),
                notary_name: Yup.string().required(
                    'El nombre del notatio es requerido',
                ),
                notary_lastname: Yup.string().required(
                    'El primer apellido del notario es requerido',
                ),
                notary_second_lastname: Yup.string(),
                notary_protocol_number: Yup.string().required(
                    'El número de protocolo del notario es requerido',
                ),
                notary_protocol_date: Yup.string().required(
                    'La fecha de inscripción del notario es requerida',
                ),
            }),
        )
        .min(1),
    products: Yup.array().of(
        Yup.object({
            solution_managment_id: Yup.string().required(
                'El nombre de la solución es requerido',
            ),
            product_managment_id: Yup.string().required(
                'El nombre del producto es requerido',
            ),
            package_managment_id: Yup.string().required(
                'El nombre del paquete es requerido',
            ),
            price: Yup.string().required(
                'El precio de la solución es requerido',
            ),
        }),
    ),
    has_printer: Yup.string(),
})

const steps = [0, 1, 2, 3, 4, 5, 6, 7]

export const FillClientData: FC<Props> = ({
    opportunity,
    segment,
    client,
    isOpen,
    onClose,
}) => {
    const { nextStep, prevStep, reset, activeStep } = useSteps({
        initialStep: 0,
    })
    const toast = useToast()

    const { updateClient } = useClient({
        client_id: client?.id,
    })
    const [isLoading, setIsLoading] = useState(false)
    const [volunteerRepresentatives, setVolunteerRepresentatives] = useState([])
    const [representativesTypes, setRepresentativesTypes] = useState(null)
    const [products, setProducts] = useState(null)
    const [clientId, setClientId] = useState(null)
    const [solutions, setSolutions] = useState([])

    useEffect(() => {
        const fetchVolunteer = async () => {
            const volunteers = await getVolunteerRepresentatives()
            setVolunteerRepresentatives(volunteers)
        }

        const fetchRepresentatives = async () => {
            const representatives = await getRepresentativeTypes()
            setRepresentativesTypes(representatives)
        }

        const fetchProducts = async () => {
            const products = await getProducts()
            setProducts(products)
        }

        const fetchSolutions = async () => {
            const solutions = await getSolutions()
            setSolutions(solutions)
        }

        fetchVolunteer()
        fetchRepresentatives()
        fetchProducts()
        fetchSolutions()
    }, [])

    const formik = useFormik({
        initialValues: {
            diagnostic_test: true,
            name: client?.name
                ? !client.name.includes('.new')
                    ? client.name.split(' ')[0] || ''
                    : ''
                : '',
            lastName: client?.name
                ? !client.name.includes('.new')
                    ? client.name.split(' ')[1] || ''
                    : ''
                : '',
            phone: client?.phone || opportunity?.phone?.replace('+', '') || '',
            email: client?.email || opportunity?.email || '',
            client_type: client?.client_type || 'a',
            dni: client?.dni || '',
            postal_code: client?.postal_code || '',
            town: client?.town || '',
            province: client?.province || '',
            home_address: client?.home_address || '',
            comment: client?.comment || '',
            representative_volunteer_id:
                client?.representative_volunteer_id || 4,
            activity: client?.activity || '',
            cnae: client?.cnae || '',
            iae: client?.iae || '',
            antiquity: client?.iae || '',
            enterprise_created_date: '',
            has_printer: HAS_PRINTER_OPTIONS.yes.value,
            representatives:
                client?.representatives.length > 0
                    ? client?.representatives?.map(item => {
                          let newRepresentative = {
                              ...item,
                              representative_position: item.position,
                          }
                          delete newRepresentative.position
                          return newRepresentative
                      })
                    : [
                          {
                              name: '',
                              lastname: '',
                              second_lastname: '',
                              dni: '',
                              phone: '',
                              email: '',
                              representative_position: 'adm_unique',
                              notary_name: '',
                              notary_lastname: '',
                              notary_second_lastname: '',
                              notary_protocol_number: '',
                              notary_protocol_date: '',
                          },
                      ],
            products: [
                {
                    solution_managment_id: '',
                    product_managment_id: '',
                    package_managment_id: '',
                    price: '',
                },
            ],
        },
        validationSchema: Schema,
        onSubmit: () => {},
    })

    const sendClient = async customerData => {
        try {
            setIsLoading(true)

            await updateClient({
                client_id: client?.id,
                data: customerData,
            })

            setIsLoading(false)
            reset()
            formik.resetForm()
            onClose()
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }

        setIsLoading(false)
    }

    const handleNextStep = async () => {
        if ([0, 5, 6].includes(activeStep)) {
            nextStep()
        }

        const validations = await formik.validateForm(formik.values)

        if (activeStep === 1) {
            setClientId(null)

            if (
                validations.name ||
                validations.dni ||
                validations.home_address ||
                validations.postal_code ||
                validations.town ||
                validations.province ||
                (validations.lastName && formik.values.client_type === 'a')
            ) {
                formik.setErrors({
                    name: validations.name,
                    lastName:
                        formik.values.client_type === 'a'
                            ? validations.lastName
                            : null,
                    dni: validations.dni,
                    home_address: validations.home_address,
                    postal_code: validations.postal_code,
                    town: validations.town,
                    province: validations.province,
                })
                formik.setTouched({
                    name: true,
                    lastName: formik.values.client_type === 'a' ? true : false,
                    dni: true,
                    home_address: true,
                    postal_code: true,
                    town: true,
                    province: true,
                })
            } else {
                if (formik.values.client_type === 's') {
                    if (!validateCIF(formik.values.dni)) {
                        await formik.setFieldTouched('dni', true)
                        formik.setFieldError('dni', 'El CIF es inválido')
                    } else {
                        try {
                            setIsLoading(true)
                            await verifyDNI({
                                dni: formik.values.dni,
                            })
                            nextStep()
                        } catch (error) {
                            formik.setErrors({
                                dni: 'Ya existe un cliente con este CIF',
                            })
                            setClientId(error.cause)
                        }
                        setIsLoading(false)
                    }
                }
                if (formik.values.client_type === 'a') {
                    if (!validateDNI(formik.values.dni)) {
                        await formik.setFieldTouched('dni', true)
                        formik.setFieldError('dni', 'El NIF es inválido')
                    } else {
                        try {
                            setIsLoading(true)
                            await verifyDNI({
                                dni: formik.values.dni,
                            })
                            nextStep()
                        } catch (error) {
                            formik.setErrors({
                                dni: 'Ya existe un cliente con este NIF',
                            })
                            setClientId(error.cause)
                        }
                        setIsLoading(false)
                    }
                }
            }
        }

        if (activeStep === 2) {
            if (validations.phone || validations.email) {
                formik.setFieldError('phone', validations.phone)
                formik.setFieldError('email', validations.email)
                formik.setTouched({ phone: true, email: true })
            } else {
                try {
                    setIsLoading(true)
                    await verifyEmail({
                        email: formik.values.email,
                        exclude_client_id: client.id,
                    })
                    nextStep()
                } catch (error) {
                    await formik.setTouched({ email: true })
                    await formik.setErrors({
                        email: 'El correo ya esta en uso',
                    })
                }
                setIsLoading(false)
            }
        }

        if (activeStep === 3) {
            if (formik.values.client_type === 'a') {
                if (segment !== 1) {
                    nextStep()
                }
                {
                    if (validations.representatives?.length > 0) {
                        if (
                            // @ts-ignore
                            validations.representatives[0].name ||
                            // @ts-ignore
                            validations.representatives[0].lastname ||
                            // @ts-ignore
                            validations.representatives[0].dni ||
                            // @ts-ignore
                            validations.representatives[0].phone ||
                            // @ts-ignore
                            validations.representatives[0].email
                        ) {
                            // @ts-ignore
                            formik.setFieldError(
                                'representatives[0].name',
                                validations.representatives[0].name,
                            )
                            formik.setFieldTouched(
                                'representatives[0].name',
                                true,
                            )
                            // @ts-ignore
                            formik.setFieldError(
                                'representatives[0].lastname',
                                validations.representatives[0].lastname,
                            )
                            formik.setFieldTouched(
                                'representatives[0].lastname',
                                true,
                            )
                            // @ts-ignore
                            formik.setFieldError(
                                'representatives[0].dni',
                                validations.representatives[0].dni,
                            )
                            formik.setFieldTouched(
                                'representatives[0].dni',
                                true,
                            )
                            // @ts-ignore
                            formik.setFieldError(
                                'representatives[0].phone',
                                validations.representatives[0].phone,
                            )
                            formik.setFieldTouched(
                                'representatives[0].phone',
                                true,
                            )
                            // @ts-ignore
                            formik.setFieldError(
                                'representatives[0].email',
                                validations.representatives[0].email,
                            )
                            formik.setFieldTouched(
                                'representatives[0].email',
                                true,
                            )
                        } else {
                            if (
                                !validateDNI(
                                    formik.values.representatives[0].dni,
                                )
                            ) {
                                await formik.setFieldTouched(
                                    'representatives[0].dni',
                                    true,
                                )
                                await formik.setFieldError(
                                    'representatives[0].dni',
                                    'El NIF es inválido',
                                )
                            } else {
                                nextStep()
                            }
                        }
                    } else {
                        if (
                            !validateDNI(formik.values.representatives[0].dni)
                        ) {
                            await formik.setFieldTouched(
                                'representatives[0].dni',
                                true,
                            )
                            await formik.setFieldError(
                                'representatives[0].dni',
                                'El NIF es inválido',
                            )
                        } else {
                            nextStep()
                        }
                    }
                }
            } else {
                if (segment !== 1) {
                    if (
                        representativesTypes[
                            formik.values.representatives[0]
                                .representative_position
                        ].type === 'A'
                    ) {
                        if (validations.representatives?.length > 0) {
                            if (
                                // @ts-ignore
                                validations.representatives[0].name ||
                                // @ts-ignore
                                validations.representatives[0].lastname ||
                                // @ts-ignore
                                validations.representatives[0].dni
                            ) {
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].name',
                                    validations.representatives[0].name,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].name',
                                    true,
                                )
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].lastname',
                                    validations.representatives[0].lastname,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].lastname',
                                    true,
                                )
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].dni',
                                    validations.representatives[0].dni,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].dni',
                                    true,
                                )
                            } else {
                                if (
                                    !validateDNI(
                                        formik.values.representatives[0].dni,
                                    )
                                ) {
                                    await formik.setFieldTouched(
                                        'representatives[0].dni',
                                        true,
                                    )
                                    await formik.setFieldError(
                                        'representatives[0].dni',
                                        'El NIF es inválido',
                                    )
                                } else {
                                    nextStep()
                                }
                            }
                        } else {
                            if (
                                !validateDNI(
                                    formik.values.representatives[0].dni,
                                )
                            ) {
                                await formik.setFieldTouched(
                                    'representatives[0].dni',
                                    true,
                                )
                                await formik.setFieldError(
                                    'representatives[0].dni',
                                    'El NIF es inválido',
                                )
                            } else {
                                nextStep()
                            }
                        }
                    } else if (
                        representativesTypes[
                            formik.values.representatives[0]
                                .representative_position
                        ].type === 'B'
                    ) {
                        if (validations.representatives?.length > 0) {
                            let canNext = true
                            // @ts-ignore
                            validations.representatives.forEach(
                                (element, index) => {
                                    if (
                                        element?.name ||
                                        element?.lastname ||
                                        element.dni
                                    ) {
                                        canNext = false
                                        // @ts-ignore
                                        formik.setFieldError(
                                            `representatives[${index}].name`,
                                            validations.representatives[index]
                                                .name,
                                        )
                                        // @ts-ignore
                                        formik.setFieldError(
                                            `representatives[${index}].lastname`,
                                            validations.representatives[index]
                                                .lastname,
                                        )
                                        // @ts-ignore
                                        formik.setFieldError(
                                            `representatives[${index}].dni`,
                                            validations.representatives[index]
                                                .dni,
                                        )
                                        formik.setFieldTouched(
                                            `representatives[${index}].name`,
                                            true,
                                        )
                                        formik.setFieldTouched(
                                            `representatives[${index}].lastname`,
                                            true,
                                        )
                                        formik.setFieldTouched(
                                            `representatives[${index}].dni`,
                                            true,
                                        )
                                    }
                                },
                            )
                            if (canNext) {
                                let isValidNIF = true

                                formik.values.representatives.forEach(
                                    async (element, index) => {
                                        if (!validateDNI(element.dni)) {
                                            await formik.setFieldTouched(
                                                `representatives[${index}].dni`,
                                                true,
                                            )
                                            formik.setFieldError(
                                                `representatives[${index}].dni`,
                                                'El NIF es inválido',
                                            )
                                            isValidNIF = false
                                        }
                                    },
                                )

                                if (isValidNIF) {
                                    nextStep()
                                }
                            }
                        } else {
                            let isValidNIF = true

                            formik.values.representatives.forEach(
                                async (element, index) => {
                                    if (!validateDNI(element.dni)) {
                                        await formik.setFieldTouched(
                                            `representatives[${index}].dni`,
                                            true,
                                        )
                                        formik.setFieldError(
                                            `representatives[${index}].dni`,
                                            'El NIF es inválido',
                                        )
                                        isValidNIF = false
                                    }
                                },
                            )

                            if (isValidNIF) {
                                nextStep()
                            }
                        }
                    } else {
                        if (validations.representatives?.length > 0) {
                            if (
                                // @ts-ignore
                                !validations.representatives[0].name &&
                                // @ts-ignore
                                !validations.representatives[0].lastname &&
                                // @ts-ignore
                                !validations.representatives[0].dni &&
                                // @ts-ignore
                                !validations.representatives[0].notary_name &&
                                // @ts-ignore
                                !validations.representatives[0]
                                    .notary_lastname &&
                                // @ts-ignore
                                !validations.representatives[0]
                                    .notary_protocol_number &&
                                // @ts-ignore
                                !validations.representatives[0]
                                    .notary_protocol_date
                            ) {
                                if (
                                    !validateDNI(
                                        formik.values.representatives[0].dni,
                                    )
                                ) {
                                    await formik.setFieldTouched(
                                        'representatives[0].dni',
                                        true,
                                    )
                                    await formik.setFieldError(
                                        'representatives[0].dni',
                                        'El NIF es inválido',
                                    )
                                } else {
                                    nextStep()
                                }
                            } else {
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].name',
                                    validations.representatives[0].name,
                                )
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].lastname',
                                    validations.representatives[0].lastname,
                                )
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].dni',
                                    validations.representatives[0].dni,
                                )
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].notary_name',
                                    validations.representatives[0].notary_name,
                                )
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].notary_lastname',
                                    validations.representatives[0]
                                        .notary_lastname,
                                )
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].notary_protocol_number',
                                    validations.representatives[0]
                                        .notary_protocol_number,
                                )
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].notary_protocol_date',
                                    validations.representatives[0]
                                        .notary_protocol_date,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].name',
                                    true,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].lastname',
                                    true,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].dni',
                                    true,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].notary_name',
                                    true,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].notary_lastname',
                                    true,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].notary_protocol_number',
                                    true,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].notary_protocol_date',
                                    true,
                                )
                            }
                        } else {
                            if (
                                !validateDNI(
                                    formik.values.representatives[0].dni,
                                )
                            ) {
                                await formik.setFieldTouched(
                                    'representatives[0].dni',
                                    true,
                                )
                                await formik.setFieldError(
                                    'representatives[0].dni',
                                    'El NIF es inválido',
                                )
                            } else {
                                nextStep()
                            }
                        }
                    }
                } else {
                    if (
                        representativesTypes[
                            formik.values.representatives[0]
                                .representative_position
                        ].type === 'B'
                    ) {
                        if (validations.representatives?.length > 0) {
                            let canNext = true
                            // @ts-ignore
                            validations.representatives.forEach(
                                (element, index) => {
                                    if (
                                        element?.name ||
                                        element?.lastname ||
                                        element?.dni ||
                                        element?.phone ||
                                        element?.email
                                    ) {
                                        canNext = false
                                        // @ts-ignore
                                        formik.setFieldError(
                                            `representatives[${index}].name`,
                                            validations.representatives[index]
                                                .name,
                                        )
                                        // @ts-ignore
                                        formik.setFieldError(
                                            `representatives[${index}].lastname`,
                                            validations.representatives[index]
                                                .lastname,
                                        )
                                        // @ts-ignore
                                        formik.setFieldError(
                                            `representatives[${index}].dni`,
                                            validations.representatives[index]
                                                .dni,
                                        )
                                        // @ts-ignore
                                        formik.setFieldError(
                                            `representatives[${index}].phone`,
                                            validations.representatives[index]
                                                .phone,
                                        )
                                        // @ts-ignore
                                        formik.setFieldError(
                                            `representatives[${index}].email`,
                                            validations.representatives[index]
                                                .email,
                                        )
                                        formik.setFieldTouched(
                                            `representatives[${index}].name`,
                                            true,
                                        )
                                        formik.setFieldTouched(
                                            `representatives[${index}].lastname`,
                                            true,
                                        )
                                        formik.setFieldTouched(
                                            `representatives[${index}].dni`,
                                            true,
                                        )
                                        formik.setFieldTouched(
                                            `representatives[${index}].phone`,
                                            true,
                                        )
                                        formik.setFieldTouched(
                                            `representatives[${index}].email`,
                                            true,
                                        )
                                    }

                                    if (index === 0) {
                                        if (
                                            // @ts-ignore
                                            validations.representatives[0]
                                                ?.notary_name ||
                                            // @ts-ignore
                                            validations.representatives[0]
                                                ?.notary_lastname ||
                                            // @ts-ignore
                                            validations.representatives[0]
                                                ?.notary_protocol_number ||
                                            // @ts-ignore
                                            validations.representatives[0]
                                                ?.notary_protocol_date
                                        ) {
                                            canNext = false
                                            // @ts-ignore
                                            formik.setFieldError(
                                                'representatives[0].notary_name',
                                                validations.representatives[0]
                                                    .notary_name,
                                            )
                                            // @ts-ignore
                                            formik.setFieldError(
                                                'representatives[0].notary_lastname',
                                                validations.representatives[0]
                                                    .notary_lastname,
                                            )
                                            // @ts-ignore
                                            formik.setFieldError(
                                                'representatives[0].notary_protocol_number',
                                                validations.representatives[0]
                                                    .notary_protocol_number,
                                            )
                                            // @ts-ignore
                                            formik.setFieldError(
                                                'representatives[0].notary_protocol_date',
                                                validations.representatives[0]
                                                    .notary_protocol_date,
                                            )
                                            formik.setFieldTouched(
                                                'representatives[0].notary_name',
                                                true,
                                            )
                                            formik.setFieldTouched(
                                                'representatives[0].notary_lastname',
                                                true,
                                            )
                                            formik.setFieldTouched(
                                                'representatives[0].notary_protocol_number',
                                                true,
                                            )
                                            formik.setFieldTouched(
                                                'representatives[0].notary_protocol_date',
                                                true,
                                            )
                                        }
                                    }
                                },
                            )
                            if (canNext) {
                                let isValidNIF = true

                                formik.values.representatives.forEach(
                                    async (element, index) => {
                                        if (!validateDNI(element.dni)) {
                                            await formik.setFieldTouched(
                                                `representatives[${index}].dni`,
                                                true,
                                            )
                                            formik.setFieldError(
                                                `representatives[${index}].dni`,
                                                'El NIF es inválido',
                                            )
                                            isValidNIF = false
                                        }
                                    },
                                )

                                if (isValidNIF) {
                                    nextStep()
                                }
                            }
                        } else {
                            let isValidNIF = true

                            formik.values.representatives.forEach(
                                async (element, index) => {
                                    if (!validateDNI(element.dni)) {
                                        await formik.setFieldTouched(
                                            `representatives[${index}].dni`,
                                            true,
                                        )
                                        formik.setFieldError(
                                            `representatives[${index}].dni`,
                                            'El NIF es inválido',
                                        )
                                        isValidNIF = false
                                    }
                                },
                            )

                            if (isValidNIF) {
                                nextStep()
                            }
                        }
                    } else {
                        if (validations.representatives?.length > 0) {
                            if (
                                // @ts-ignore
                                !validations.representatives[0].name &&
                                // @ts-ignore
                                !validations.representatives[0].lastname &&
                                // @ts-ignore
                                !validations.representatives[0].dni &&
                                // @ts-ignore
                                !validations.representatives[0].phone &&
                                // @ts-ignore
                                !validations.representatives[0].email &&
                                // @ts-ignore
                                !validations.representatives[0].notary_name &&
                                // @ts-ignore
                                !validations.representatives[0]
                                    .notary_lastname &&
                                // @ts-ignore
                                !validations.representatives[0]
                                    .notary_protocol_number &&
                                // @ts-ignore
                                !validations.representatives[0]
                                    .notary_protocol_date
                            ) {
                                if (
                                    !validateDNI(
                                        formik.values.representatives[0].dni,
                                    )
                                ) {
                                    await formik.setFieldTouched(
                                        'representatives[0].dni',
                                        true,
                                    )
                                    formik.setFieldError(
                                        'representatives[0].dni',
                                        'El NIF es inválido',
                                    )
                                } else {
                                    nextStep()
                                }
                            } else {
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].name',
                                    validations.representatives[0].name,
                                )
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].lastname',
                                    validations.representatives[0].lastname,
                                )
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].dni',
                                    validations.representatives[0].dni,
                                )
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].phone',
                                    validations.representatives[0].phone,
                                )
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].email',
                                    validations.representatives[0].email,
                                )
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].notary_name',
                                    validations.representatives[0].notary_name,
                                )
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].notary_lastname',
                                    validations.representatives[0]
                                        .notary_lastname,
                                )
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].notary_protocol_number',
                                    validations.representatives[0]
                                        .notary_protocol_number,
                                )
                                // @ts-ignore
                                formik.setFieldError(
                                    'representatives[0].notary_protocol_date',
                                    validations.representatives[0]
                                        .notary_protocol_date,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].name',
                                    true,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].lastname',
                                    true,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].dni',
                                    true,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].phone',
                                    true,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].email',
                                    true,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].notary_name',
                                    true,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].notary_lastname',
                                    true,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].notary_protocol_number',
                                    true,
                                )
                                formik.setFieldTouched(
                                    'representatives[0].notary_protocol_date',
                                    true,
                                )
                            }
                        } else {
                            if (
                                !validateDNI(
                                    formik.values.representatives[0].dni,
                                )
                            ) {
                                await formik.setFieldTouched(
                                    'representatives[0].dni',
                                    true,
                                )
                                await formik.setFieldError(
                                    'representatives[0].dni',
                                    'El NIF es inválido',
                                )
                            } else {
                                nextStep()
                            }
                        }
                    }
                }
            }
        }

        if (activeStep === 4) {
            let canGo = true

            if (
                formik.values.antiquity === 'Menos de 6 meses' &&
                validations.enterprise_created_date
            ) {
                formik.setFieldError(
                    'enterprise_created_date',
                    validations.enterprise_created_date,
                )
                formik.setFieldTouched('enterprise_created_date', true)
                canGo = false
            }

            if (validations.activity) {
                formik.setErrors({
                    activity: validations.activity,
                })
                formik.setTouched({ activity: true })
            } else {
                if (canGo) {
                    nextStep()
                }
            }
        }

        if (activeStep === 7) {
            let representativesToSave = []

            if (formik.values.client_type === 'a') {
                let representativesToSave = []

                if (segment === 1) {
                    representativesToSave = [
                        {
                            name: formik.values.representatives[0].name,
                            lastname: formik.values.representatives[0].lastname,
                            second_lastname:
                                formik.values.representatives[0]
                                    .second_lastname,
                            dni: formik.values.representatives[0].dni.toUpperCase(),
                            phone: formik.values.representatives[0].phone,
                            email: formik.values.representatives[0].email,
                            position: 'auto_person',
                        },
                    ]
                }

                sendClient({
                    opportunity_id: opportunity.id,
                    client_type: formik.values.client_type,
                    name: `${formik.values.name} ${formik.values.lastName}`,
                    dni: formik.values.dni.toUpperCase(),
                    phone: `+${formik.values.phone}`,
                    email: formik.values.email,
                    home_address: formik.values.home_address,
                    postal_code: formik.values.postal_code,
                    province: formik.values.province,
                    town: formik.values.town,
                    activity: formik.values.activity,
                    cnae: formik.values.cnae,
                    iae: formik.values.iae,
                    antiquity: formik.values.antiquity,
                    enterprise_created_date:
                        formik.values.enterprise_created_date,
                    representatives: representativesToSave,
                    representative_volunteer_id:
                        formik.values.representative_volunteer_id,
                    products: formik.values.products,
                    comment: formik.values.comment,
                    release_date: new Date().toJSON().split('T')[0],
                })
            }

            if (formik.values.client_type === 's') {
                if (
                    representativesTypes[
                        formik.values.representatives[0].representative_position
                    ].type === 'A'
                ) {
                    representativesToSave = [
                        {
                            name: formik.values.representatives[0].name,
                            lastname: formik.values.representatives[0].lastname,
                            second_lastname:
                                formik.values.representatives[0]
                                    .second_lastname,
                            dni: formik.values.representatives[0].dni.toUpperCase(),
                            phone: formik.values.representatives[0].phone,
                            email: formik.values.representatives[0].email,
                            position:
                                formik.values.representatives[0]
                                    .representative_position,
                            notary_name:
                                formik.values.representatives[0].notary_name,
                            notary_lastname:
                                formik.values.representatives[0]
                                    .notary_lastname,
                            notary_second_lastname:
                                formik.values.representatives[0]
                                    .notary_second_lastname,
                            notary_protocol_number:
                                formik.values.representatives[0]
                                    .notary_protocol_number,
                            notary_protocol_date:
                                formik.values.representatives[0]
                                    .notary_protocol_date,
                        },
                    ]
                } else if (
                    representativesTypes[
                        formik.values.representatives[0].representative_position
                    ].type === 'B'
                ) {
                    representativesToSave = formik.values.representatives.map(
                        item => {
                            return {
                                name: item.name,
                                lastname: item.lastname,
                                second_lastname: item.second_lastname,
                                dni: item.dni,
                                phone: item.phone,
                                email: item.email,
                                position:
                                    representativesTypes[
                                        item.representative_position
                                    ].name,
                                notary_name:
                                    formik.values.representatives[0]
                                        .notary_name,
                                notary_lastname:
                                    formik.values.representatives[0]
                                        .notary_lastname,
                                notary_second_lastname:
                                    formik.values.representatives[0]
                                        .notary_second_lastname,
                                notary_protocol_number:
                                    formik.values.representatives[0]
                                        .notary_protocol_number,
                                notary_protocol_date:
                                    formik.values.representatives[0]
                                        .notary_protocol_date,
                            }
                        },
                    )
                } else {
                    representativesToSave = [
                        {
                            name: formik.values.representatives[0].name,
                            lastname: formik.values.representatives[0].lastname,
                            second_lastname:
                                formik.values.representatives[0]
                                    .second_lastname,
                            dni: formik.values.representatives[0].dni.toUpperCase(),
                            phone: formik.values.representatives[0].phone,
                            email: formik.values.representatives[0].email,
                            position:
                                formik.values.representatives[0]
                                    .representative_position,
                            notary_name:
                                formik.values.representatives[0].notary_name,
                            notary_lastname:
                                formik.values.representatives[0]
                                    .notary_lastname,
                            notary_second_lastname:
                                formik.values.representatives[0]
                                    .notary_second_lastname,
                            notary_protocol_number:
                                formik.values.representatives[0]
                                    .notary_protocol_number,
                            notary_protocol_date:
                                formik.values.representatives[0]
                                    .notary_protocol_date,
                        },
                    ]
                }

                sendClient({
                    opportunity_id: opportunity.id,
                    client_type: formik.values.client_type,
                    name: formik.values.name,
                    dni: formik.values.dni.toUpperCase(),
                    phone: `+${formik.values.phone}`,
                    email: formik.values.email,
                    home_address: formik.values.home_address,
                    postal_code: formik.values.postal_code,
                    province: formik.values.province,
                    town: formik.values.town,
                    activity: formik.values.activity,
                    cnae: formik.values.cnae,
                    iae: formik.values.iae,
                    antiquity: formik.values.antiquity,
                    enterprise_created_date:
                        formik.values.enterprise_created_date,
                    representatives: representativesToSave,
                    representative_volunteer_id:
                        formik.values.representative_volunteer_id,
                    products: formik.values.products,
                    comment: formik.values.comment,
                    release_date: new Date().toJSON().split('T')[0],
                })
            }
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    color="#474747"
                    display="flex"
                    fontSize={18}
                    fontWeight={600}
                    justifyContent="center"
                    py={4}>
                    Rellenar datos del cliente
                </ModalHeader>

                <Divider />

                <form onSubmit={formik.handleSubmit}>
                    <ModalBody px={12} mb={6}>
                        <Flex flexDir="column" width="100%">
                            <Steps
                                labelOrientation="vertical"
                                size="xs"
                                colorScheme="telegram"
                                activeStep={activeStep}>
                                <Step
                                    label={
                                        activeStep === 0 && 'Tipo de cliente'
                                    }
                                    color={colors.secondary}
                                    icon={CircleIcon}>
                                    <Step1WPreliminary formik={formik} />
                                </Step>

                                <Step
                                    label={
                                        activeStep === 1 &&
                                        'Información del cliente'
                                    }
                                    color={colors.secondary}
                                    icon={CircleIcon}>
                                    <Step2
                                        formik={formik}
                                        client_id={clientId}
                                        onClose={onClose}
                                    />
                                </Step>
                                <Step
                                    label={activeStep === 2 && 'Contacto'}
                                    color={colors.secondary}
                                    icon={CircleIcon}>
                                    <Step3
                                        formik={formik}
                                        representativesTypes={
                                            representativesTypes
                                        }
                                    />
                                </Step>
                                <Step
                                    label={activeStep === 3 && 'Representante'}
                                    color={colors.secondary}
                                    icon={CircleIcon}>
                                    {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore */}
                                    <Step4
                                        formik={formik}
                                        representativesTypes={
                                            representativesTypes
                                        }
                                        segment={segment}
                                    />
                                </Step>
                                <Step
                                    label={
                                        activeStep === 4 &&
                                        'Información de la Empresa'
                                    }
                                    color={colors.secondary}
                                    icon={CircleIcon}>
                                    <StepInfoCompany
                                        formik={formik}
                                        representativesTypes={
                                            representativesTypes
                                        }
                                        requires={false}
                                    />
                                </Step>
                                <Step
                                    label={
                                        activeStep === 5 &&
                                        'Representante voluntario'
                                    }
                                    color={colors.secondary}
                                    icon={CircleIcon}>
                                    <Step5
                                        formik={formik}
                                        representativesTypes={
                                            representativesTypes
                                        }
                                        volunteerRepresentatives={
                                            volunteerRepresentatives
                                        }
                                    />
                                </Step>
                                <Step
                                    label={activeStep === 6 && 'Producto'}
                                    color={colors.secondary}
                                    icon={CircleIcon}>
                                    {solutions.length > 0 && (
                                        <Step6
                                            formik={formik}
                                            opportunityDistributor={
                                                opportunity?.distributor
                                            }
                                            representativesTypes={
                                                representativesTypes
                                            }
                                            solutions={solutions}
                                        />
                                    )}
                                </Step>
                                <Step
                                    label={activeStep === 7 && 'Comentario'}
                                    color={colors.secondary}
                                    icon={CircleIcon}>
                                    <Step7
                                        formik={formik}
                                        representativesTypes={
                                            representativesTypes
                                        }
                                    />
                                </Step>
                            </Steps>
                        </Flex>
                    </ModalBody>

                    <Divider />

                    <ModalFooter px={0}>
                        <Box display="flex" justifyContent="center" w="full">
                            {activeStep > 0 && (
                                <Button
                                    bg="white"
                                    border="1px"
                                    borderColor={colors.secondary}
                                    color={colors.secondary}
                                    fontSize={12}
                                    fontWeight={500}
                                    h="32px"
                                    mr={10}
                                    w={112}
                                    onClick={prevStep}
                                    _active={{
                                        transform: 'scale(0.98)',
                                    }}
                                    _hover={{}}>
                                    Atrás
                                </Button>
                            )}
                            <Button
                                bg={colors.secondary}
                                border="1px"
                                color="white"
                                fontSize={12}
                                fontWeight={500}
                                h="32px"
                                isLoading={isLoading}
                                mr={10}
                                type={
                                    activeStep === steps.length
                                        ? 'submit'
                                        : 'button'
                                }
                                w={112}
                                onClick={handleNextStep}
                                _active={{
                                    transform: 'scale(0.98)',
                                }}
                                _hover={{}}>
                                Continuar
                            </Button>
                        </Box>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}
