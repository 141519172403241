import React, { FC, useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Text,
    Button,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Divider,
} from '@chakra-ui/react'
import { colors } from '@/utils/const'
import { Opportunity } from '@/interfaces'
import { useOpportunity } from '@/hooks/opportunities'
import { useVouchers } from '@/hooks/vouchers'

interface Props {
    opportunity: Opportunity
    isOpen: boolean
    onClose: () => void
}

const SIX_MONTHS = 15634800000

const Schema = Yup.object().shape({
    resolution_date: Yup.string().required(
        'La fecha de resolución es requerida',
    ),
    amount: Yup.string().required('El importe es requerido'),
    caducity_date: Yup.string().required('La fecha de caducidad es requerida'),
    bonus_expedient_number: Yup.string().required(
        'El número de expediente es requerido',
    ),
})

export const ApprovedBonusModal: FC<Props> = ({
    opportunity,
    isOpen,
    onClose,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const { updateOpportunity, setStatusOpportunity } = useOpportunity({
        id: opportunity?.id,
    })
    const { createVoucher } = useVouchers({})
    const toast = useToast()

    const formik = useFormik({
        initialValues: {
            resolution_date: '',
            amount: '',
            caducity_date: '',
            bonus_expedient_number: opportunity?.bonus_expedient_number || '',
        },
        validationSchema: Schema,
        onSubmit: async values => {
            if (opportunity?.client?.id) {
                try {
                    setIsLoading(true)

                    await createVoucher({
                        voucherData: {
                            client_id: opportunity?.client.id,
                            amount: parseInt(values.amount),
                            expiration_date: new Date(values.caducity_date)
                                .toJSON()
                                .split('T')[0],
                            resolution_date: values.resolution_date,
                        },
                    })

                    await updateOpportunity({
                        opportunity_id: opportunity.id,
                        data: {
                            bonus_expedient_number:
                                values.bonus_expedient_number,
                        },
                    })

                    await setStatusOpportunity({
                        opportunity_id: opportunity.id,
                        status: 'approved',
                    })

                    toast({
                        title: 'Éxito',
                        description: 'Oportunidad actualizada con éxito',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    })
                    formik.resetForm()
                    onClose()
                } catch (error) {
                    toast({
                        title: 'Error.',
                        description: error.message,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })
                }

                setIsLoading(false)
            }
        },
    })

    const handleSetDate = e => {
        formik.setFieldValue('resolution_date', e.target.value)

        const unixTime = new Date(e.target.value).getTime()

        formik.setFieldValue(
            'caducity_date',
            new Date(unixTime + SIX_MONTHS).toJSON().split('T')[0],
        )
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="sm">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader pt={6} pb={4} textAlign="center">
                    <Text color="#474747" fontSize={18} fontWeight={600}>
                        Gestión de bono
                    </Text>
                    <Text color={colors.primary} fontSize={12} fontWeight={400}>
                        Aprobado
                    </Text>
                </ModalHeader>

                <form onSubmit={formik.handleSubmit}>
                    <ModalBody color="#474747" p={0}>
                        <Box px="20px" mb={4}>
                            <FormControl
                                mb={4}
                                isInvalid={
                                    formik.errors.resolution_date &&
                                    formik.touched.resolution_date
                                }>
                                <FormLabel fontSize={13} fontWeight={500}>
                                    Fecha de resolución
                                </FormLabel>
                                <Input
                                    id="resolution_date"
                                    placeholder="Fecha de resolución"
                                    rounded="4px"
                                    size="sm"
                                    type="date"
                                    value={formik.values.resolution_date}
                                    onChange={handleSetDate}
                                />
                                <FormErrorMessage>
                                    {formik.errors.resolution_date}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl
                                mb={4}
                                isInvalid={
                                    formik.errors.amount &&
                                    formik.touched.amount
                                }>
                                <FormLabel fontSize={13} fontWeight={500}>
                                    Importe
                                </FormLabel>
                                <Input
                                    fontSize={13}
                                    fontWeight={500}
                                    id="amount"
                                    placeholder="Importe"
                                    rounded="4px"
                                    size="sm"
                                    value={formik.values.amount}
                                    onChange={formik.handleChange}
                                />
                                <FormErrorMessage>
                                    {formik.errors.amount}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl
                                mb={4}
                                isInvalid={
                                    formik.errors.bonus_expedient_number &&
                                    formik.touched.bonus_expedient_number
                                }>
                                <FormLabel fontSize={13} fontWeight={500}>
                                    Número de expediente
                                </FormLabel>
                                <Input
                                    fontSize={13}
                                    fontWeight={500}
                                    id="bonus_expedient_number"
                                    placeholder="Número de expediente"
                                    rounded="4px"
                                    size="sm"
                                    value={formik.values.bonus_expedient_number}
                                    onChange={formik.handleChange}
                                />
                                <FormErrorMessage>
                                    {formik.errors.bonus_expedient_number}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl
                                mb={4}
                                isInvalid={
                                    formik.errors.caducity_date &&
                                    formik.touched.caducity_date
                                }>
                                <FormLabel fontSize={13} fontWeight={500}>
                                    Fecha de caducidad
                                </FormLabel>
                                <Input
                                    id="caducity_date"
                                    placeholder="Fecha de caducidad"
                                    rounded="4px"
                                    size="sm"
                                    type="date"
                                    value={formik.values.caducity_date}
                                    onChange={formik.handleChange}
                                />
                                <FormErrorMessage>
                                    {formik.errors.caducity_date}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Divider />

                        <Box my={4} display="flex" justifyContent="center">
                            <Button
                                bg="white"
                                borderColor={colors.secondary}
                                borderWidth={1}
                                color={colors.secondary}
                                fontSize={14}
                                fontWeight={500}
                                mr={4}
                                h="32px"
                                w="130px"
                                onClick={onClose}
                                _active={{
                                    transform: 'scale(0.98)',
                                }}
                                _hover={{}}>
                                Atrás
                            </Button>
                            <Button
                                bg={colors.secondary}
                                color="white"
                                fontSize={14}
                                fontWeight={500}
                                isLoading={isLoading}
                                h="32px"
                                type="submit"
                                w="130px"
                                _active={{
                                    transform: 'scale(0.98)',
                                }}
                                _hover={{}}>
                                Aceptar
                            </Button>
                        </Box>
                    </ModalBody>
                </form>
            </ModalContent>
        </Modal>
    )
}
