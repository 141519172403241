import React, { FC, useEffect, useState } from 'react'

import { mutate, useSWRConfig } from 'swr'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Text,
    useToast,
    Grid,
    GridItem,
    Button,
    FormControl,
    Input,
    FormErrorMessage,
    Center,
    IconButton,
    HStack,
    VStack,
} from '@chakra-ui/react'
import { Opportunity, ProfilesQuestions, Questions } from '@/interfaces'
import { colors } from '@/utils/const'
import {
    getProfilesQuestions,
    getQuestions,
    redirectEmail,
    useClient,
} from '@/hooks/client'
import { EditLine } from '../icons'

interface Props {
    opportunity: Opportunity
    userRol: string
}

const Schema = Yup.object().shape({
    email: Yup.string()
        .email('Correo inválido')
        .required('El correo es requerido'),
})

export const PymeDetailsCard: FC<Props> = ({ opportunity, userRol }) => {
    const { client } = opportunity
    const { updateClient } = useClient({ client_id: client?.id })
    const [questionsTest, setQuestionsTest] = useState<Questions[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [profilesQuestions, setProfilesQuestions] = useState<
        ProfilesQuestions[]
    >([])
    const [isLoadingResend, setIsLoadingResend] = useState(false)
    const toast = useToast()

    const { email, password } = client?.acelera_pyme_data
        ? JSON.parse(client?.acelera_pyme_data)
        : { email: '', password: '' }

    useEffect(() => {
        const fetchQuestions = async () => {
            const questions = await getQuestions()
            setQuestionsTest(questions)
        }

        const fetchProfilesQuestions = async () => {
            const profiles = await getProfilesQuestions()
            setProfilesQuestions(profiles)
        }

        fetchQuestions()
        fetchProfilesQuestions()
    }, [])

    const formik = useFormik({
        initialValues: {
            email: (email as string) || '',
        },
        validationSchema: Schema,
        onSubmit: async values => {
            try {
                setIsLoading(true)

                await updateClient({
                    client_id: client?.id,
                    data: {
                        acelera_pyme_data: JSON.stringify({
                            email: values.email,
                            password,
                        }),
                    },
                })

                await mutate(`/api/opportunity/${opportunity?.id}`)
                formik.resetForm()

                toast({
                    title: 'Éxito',
                    description: 'Email cambiado con éxito',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            } finally {
                setIsEditing(false)
                setIsLoading(false)
            }
        },
    })

    const handleRerirectEmail = async () => {
        try {
            setIsLoadingResend(true)

            await redirectEmail({
                client_id: client.id,
            })

            toast({
                title: 'Éxito',
                description: 'Se ha reenvíado el email con éxito',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }

        setIsLoadingResend(false)
    }

    return (
        <Box
            bg="white"
            w="100%"
            borderWidth={1}
            borderRadius="xl"
            boxShadow="lg">
            <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem>
                    <Box
                        ml={4}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between">
                        <Text
                            color="#858585"
                            fontSize={14}
                            fontWeight={400}
                            mt={2}>
                            Datos Acelerapyme
                        </Text>
                        <Box display="flex" alignItems="center">
                            <AccordionButton>
                                <AccordionIcon w="35px" h="35px" />
                            </AccordionButton>
                        </Box>
                    </Box>
                    <AccordionPanel px={10}>
                        <Grid templateColumns="1fr 1fr" gap={2}>
                            <GridItem w="100%">
                                <HStack align="left" spacing={8}>
                                    <Text
                                        color="#4F4F4F"
                                        fontSize={13}
                                        fontWeight={700}>
                                        Email
                                    </Text>

                                    <IconButton
                                        aria-label={''}
                                        size="xs"
                                        bg="transparent"
                                        hidden={!client?.acelera_pyme_data}
                                        icon={
                                            <EditLine transform="scale(1.2)" />
                                        }
                                        onClick={() => setIsEditing(!isEditing)}
                                        _active={{}}
                                        _hover={{}}
                                    />
                                </HStack>

                                {isEditing ? (
                                    <form onSubmit={formik.handleSubmit}>
                                        <VStack maxW={300}>
                                            <FormControl
                                                isInvalid={
                                                    formik.errors.email &&
                                                    formik.touched.email
                                                }>
                                                <Input
                                                    id="email"
                                                    bg="white"
                                                    placeholder="Correo"
                                                    rounded="4px"
                                                    size="xs"
                                                    type="email"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    value={formik.values.email}
                                                />
                                                <FormErrorMessage>
                                                    {formik.errors.email}
                                                </FormErrorMessage>
                                            </FormControl>

                                            <Center mb={2}>
                                                <Button
                                                    bg={colors.secondary}
                                                    border="1px"
                                                    borderColor={
                                                        colors.secondary
                                                    }
                                                    color="white"
                                                    fontSize={12}
                                                    fontWeight={500}
                                                    h="24px"
                                                    isLoading={isLoading}
                                                    type="submit"
                                                    w={98}
                                                    _active={{
                                                        transform:
                                                            'scale(0.98)',
                                                    }}
                                                    _hover={{}}>
                                                    Guardar
                                                </Button>
                                            </Center>
                                        </VStack>
                                    </form>
                                ) : (
                                    <Text
                                        color="#4F4F4F"
                                        fontSize={12}
                                        fontWeight={400}
                                        ml={4}
                                        mb={4}>
                                        {email ? email : 'Sin definir'}
                                    </Text>
                                )}
                            </GridItem>

                            <GridItem w="100%">
                                <Text
                                    color="#4F4F4F"
                                    fontSize={13}
                                    fontWeight={700}>
                                    Contraseña
                                </Text>

                                <Text
                                    color="#4F4F4F"
                                    fontSize={12}
                                    fontWeight={400}
                                    ml={4}
                                    mb={4}>
                                    {password ? password : 'Sin definir'}
                                </Text>
                            </GridItem>

                            <GridItem w="100%">
                                <Text
                                    color="#4F4F4F"
                                    fontSize={13}
                                    fontWeight={700}>
                                    Perfil
                                </Text>

                                <Text
                                    color="#4F4F4F"
                                    fontSize={12}
                                    fontWeight={400}
                                    ml={4}
                                    mb={2}>
                                    {client?.question_profile_id
                                        ? profilesQuestions.find(
                                              item =>
                                                  item.id ===
                                                  client.question_profile_id,
                                          )?.name
                                        : 'Sin definir'}
                                </Text>
                            </GridItem>

                            <GridItem w="100%" mb={4}>
                                {client?.acelera_pyme_redirected ? (
                                    <>
                                        <Text
                                            color="#4F4F4F"
                                            fontSize={13}
                                            fontWeight={700}>
                                            Reenvío email
                                        </Text>

                                        <Text
                                            color="#4F4F4F"
                                            fontSize={13}
                                            ml={4}
                                            fontWeight={400}>
                                            {client.acelera_pyme_redirected}
                                        </Text>
                                    </>
                                ) : (
                                    <>
                                        {['admin', 'administrative'].includes(
                                            userRol,
                                        ) && (
                                            <Button
                                                bg={colors.primary}
                                                h="32px"
                                                color="white"
                                                fontSize={12}
                                                fontWeight={500}
                                                isLoading={isLoadingResend}
                                                onClick={handleRerirectEmail}
                                                _active={{
                                                    transform: 'scale(0.98)',
                                                }}
                                                _hover={{}}>
                                                Crear reenvío email
                                            </Button>
                                        )}
                                    </>
                                )}
                            </GridItem>
                        </Grid>

                        <Grid templateColumns="30px 1fr 1fr" gap={4}>
                            <GridItem w="100%">
                                <Text
                                    color="#4F4F4F"
                                    fontSize={13}
                                    fontWeight={700}>
                                    Nº
                                </Text>
                            </GridItem>

                            <GridItem w="100%">
                                <Text
                                    color="#4F4F4F"
                                    fontSize={13}
                                    fontWeight={700}>
                                    Preguntas
                                </Text>
                            </GridItem>

                            <GridItem w="100%">
                                <Text
                                    color="#4F4F4F"
                                    fontSize={13}
                                    fontWeight={700}>
                                    Respuestas
                                </Text>
                            </GridItem>
                        </Grid>

                        {questionsTest.map((item, index) => {
                            return (
                                <Grid
                                    key={item.id}
                                    templateColumns="30px 1fr 1fr"
                                    gap={4}>
                                    <GridItem w="100%">
                                        <Text
                                            color="#4F4F4F"
                                            fontSize={12}
                                            fontWeight={400}
                                            ml={2}
                                            mb={4}>
                                            {item.id}
                                        </Text>
                                    </GridItem>

                                    <GridItem w="100%">
                                        <Text
                                            color="#4F4F4F"
                                            fontSize={12}
                                            fontWeight={400}
                                            ml={2}
                                            mb={4}>
                                            {item.name}
                                        </Text>
                                    </GridItem>

                                    <GridItem w="100%">
                                        <Text
                                            color="#4F4F4F"
                                            fontSize={12}
                                            fontWeight={400}
                                            ml={2}
                                            mb={4}>
                                            {index === 12 ? (
                                                <>
                                                    {`${client.question_test[13].answer_id} %`}
                                                </>
                                            ) : (
                                                <>
                                                    {
                                                        item.answers.find(
                                                            item =>
                                                                item.id ===
                                                                client
                                                                    ?.question_test[
                                                                    index + 1
                                                                ]?.answer_id,
                                                        )?.name
                                                    }
                                                </>
                                            )}
                                        </Text>
                                    </GridItem>
                                </Grid>
                            )
                        })}
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Box>
    )
}
