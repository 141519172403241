//@ts-nocheck
import useSWR, { useSWRConfig } from 'swr'

import axios from '@/lib/axios'
import {
    ProccessMassiveBonusBody,
    ProcessMassiveBonusResponse,
    Voucher,
} from '@/interfaces'
import { BonusFiltersContext } from '@/context/bonusFilters'
import { useContext, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { AxiosResponse } from 'axios'
import { withPolling } from '@/utils/utils'

interface NewVoucher {
    voucherData: {
        client_id: number
        resolution_date: string
        expiration_date: string
        amount: number
    }
}

interface VouchersProps {
    pageIndex?: number
}

interface VoucherProps {
    voucher_id: number
    options?: any
}

export const useVoucher = ({ voucher_id, options = {} }: VoucherProps) => {
    const [isUpdating, setIsUpdating] = useState(false)

    const { data, error, mutate } = useSWR<Voucher>(
        `/api/voucher/${voucher_id}`,
        url => {
            if (!voucher_id) return null

            return axios
                .get(url)
                .then(res => res.data.data)
                .catch(error => {})
        },
        options,
    )

    const updateVoucher = async ({ voucher_id, data }) => {
        setIsUpdating(true)
        return axios({
            method: 'PUT',
            url: `/api/voucher/${voucher_id}`,
            data,
        })
            .then(res => {
                mutate()
                return res.data
            })
            .catch(error => {
                throw new Error(error.response.data.message)
            })
            .finally(() => {
                setIsUpdating(false)
            })
    }

    return {
        voucher: data,
        isLoading: !data && !error,
        error,
        updateVoucher,
        isUpdating,
    }
}

export const getVoucher = async ({ voucher_id }) => {
    return axios({
        url: `/api/voucher/${voucher_id}`,
        method: 'GET',
    })
        .then(res => {
            return res.data
        })
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const useVouchers = ({ pageIndex = 1 }: VouchersProps) => {
    const { bonusFilters } = useContext(BonusFiltersContext)
    let params = {
        query_type: 'paginate',
        paginate: '10',
    }

    // @ts-ignore
    if (bonusFilters.search) params.search = bonusFilters.search
    // @ts-ignore
    if (bonusFilters.status) params.status = bonusFilters.status
    // @ts-ignore
    if (bonusFilters.end_date) params.end_date = bonusFilters.end_date
    // @ts-ignore
    if (bonusFilters.start_date) params.start_date = bonusFilters.start_date
    // @ts-ignore
    if (bonusFilters.commercial_id)
        params.commercial_id = bonusFilters.commercial_id
    // @ts-ignore
    if (bonusFilters.supervisor_id)
        params.supervisor_id = bonusFilters.supervisor_id
    // @ts-ignore
    if (bonusFilters.distributor_id)
        params.distributor_id = bonusFilters.distributor_id
    // @ts-ignore
    if (bonusFilters.representative_volunteer_id)
        params.repre_vol_id = bonusFilters.representative_volunteer_id
    // @ts-ignore
    if (bonusFilters.available) params.available = bonusFilters.available

    const { data, error, mutate } = useSWR(
        `/api/voucher?page=${pageIndex}`,
        url => {
            return axios({
                method: 'GET',
                url,
                params,
            })
                .then(res => res.data)
                .catch(error => {
                    throw new Error(error.response.data.message)
                })
        },
    )

    const createVoucher = async ({ voucherData }: NewVoucher) => {
        return axios({
            method: 'POST',
            url: '/api/voucher',
            data: { ...voucherData },
        })
            .then(res => {
                mutate()
                return res.data
            })
            .catch(error => {
                throw new Error(error.response.data.message)
            })
    }

    const getStatusVouchers = async () => {
        return axios({
            method: 'GET',
            url: '/api/voucher/status',
        })
            .then(res => {
                mutate()
                return res.data
            })
            .catch(error => {
                throw new Error(error.response.data.message)
            })
    }

    return {
        vouchers: data,
        isLoading: !data && !error,
        error,
        createVoucher,
        getStatusVouchers,
    }
}

export const useExtensionVouchers = ({
    pageIndex: page = 1,
    status: extension_status = 'pending',
    search = '',
}: {
    pageIndex: number
    search?: string
    status?: string
}) => {
    const params = {
        query_type: 'paginate',
        paginate: '10',
        extension_status,
        page,
    }

    if (search) {
        params.search = search
        params.id_str = search
    }

    const { data, error } = useSWR<{
        data: Voucher[]
        links: Record<'first' | 'last' | 'prev' | 'next', string | null>
        meta: OpportunityMeta
    }>([`/api/voucher`, page, extension_status, search], ([url]) => {
        return axios({
            method: 'GET',
            url,
            params,
        })
            .then(res => res.data)
            .catch(error => {
                throw new Error(error.response.data.message)
            })
    })

    return { vouchers: data, isLoading: !data && !error, error }
}

export const assignResponsible = async ({ voucher_id, user_id }) => {
    if (!voucher_id)
        throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')

    return axios({
        method: 'POST',
        url: `/api/voucher/set-responsible`,
        data: {
            voucher_id,
            user_id,
        },
    })
        .then(res => {})
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const setStatusVoucher = async ({ voucher_id, status }) => {
    if (!voucher_id)
        throw new Error('¡Lo sentimos, ha ocurrido un error inesperado!')

    return axios({
        method: 'POST',
        url: `/api/voucher/status`,
        data: {
            voucher_id,
            status,
        },
    })
        .then(res => {})
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const proccessMassiveBonus = async (data: ProccessMassiveBonusBody) => {
    return axios<ProcessMassiveBonusResponse>({
        method: 'POST',
        url: `/api/voucher/proccess-massive-bonus`,
        data,
    })
}

export async function verifyImportStatus(importId: string) {
    const PROCCESS_DELAY = 10000

    return withPolling<AxiosResponse<{ data: ProcessMassiveBonusResponse }>>(
        () =>
            axios<{ data: ProcessMassiveBonusResponse }>({
                method: 'GET',
                url: `api/import/${importId}`,
            }),
        res => {
            return Boolean(res.data.data.data)
        },
        PROCCESS_DELAY,
    )
}

export async function getBonusExtensionExcelData(status: string) {
    return axios({
        method: 'GET',
        url: '/api/voucher',
        params: { extension_status: status },
    }).then(res => res.data.data)
}

export const getALLVouchers = ({ bonusFilters, voucherStatus, users }) => {
    let params = {
        query_type: 'all',
    }

    // @ts-ignore
    if (bonusFilters.search) params.search = bonusFilters.search
    // @ts-ignore
    if (bonusFilters.status) params.status = bonusFilters.status
    // @ts-ignore
    if (bonusFilters.end_date) params.end_date = bonusFilters.end_date
    // @ts-ignore
    if (bonusFilters.start_date) params.start_date = bonusFilters.start_date
    // @ts-ignore
    if (bonusFilters.commercial_id)
        params.commercial_id = bonusFilters.commercial_id
    // @ts-ignore
    if (bonusFilters.supervisor_id)
        params.supervisor_id = bonusFilters.supervisor_id
    // @ts-ignore
    if (bonusFilters.distributor_id)
        params.distributor_id = bonusFilters.distributor_id
    // @ts-ignore
    if (bonusFilters.representative_volunteer_id)
        params.repre_vol_id = bonusFilters.representative_volunteer_id
    // @ts-ignore
    if (bonusFilters.available) params.available = bonusFilters.available

    return axios({
        method: 'GET',
        url: '/api/voucher',
        params,
    })
        .then(res => {
            return res.data.data.map(item => {
                return {
                    ID: item.id_str,
                    'Representante voluntario':
                        item.representative_volunteer?.name,
                    Cliente: item.client?.name,
                    Estado:
                        voucherStatus?.find(
                            element => element.name === item.status,
                        )?.label || '',
                    Responsable: item.responsible?.name || 'Sin definir',
                    Supervisor: item.supervisor?.name || 'Sin definir',
                    Distribuidor: item.distributor?.name || 'Sin definir',
                    'Importe del bono': item.amount,
                    Disponible: item.available,
                    'Fecha límite': item.expiration_date
                        ? new Date(item.expiration_date).toLocaleString(
                              'es-ES',
                              {
                                  day: 'numeric',
                                  month: 'numeric',
                                  year: 'numeric',
                                  hour12: false,
                              },
                          )
                        : '',
                }
            })
        })
        .catch(error => {
            throw new Error(error.response.data.message)
        })
}

export const verifyContracts = async () => {
    return axios({
        url: `/api/voucher/solutions/verify-contracts`,
        method: 'POST',
    })
        .then(res => {})
        .catch(error => {
            throw new Error(error.response?.data?.message)
        })
}

export const useApplyForExtension = () => {
    const toast = useToast()
    const { mutate: updateSWR } = useSWRConfig()
    const [isLoading, setLoading] = useState(false)

    async function mutate(voucherId: number) {
        try {
            setLoading(true)

            await sendExtensionRequestViaWhatsApp(voucherId, 1)

            toast({
                status: 'success',
                description:
                    'Solicitud de extensión completada satisfactoriamente',
            })
        } catch (error) {
            if (error instanceof Error) {
                toast({
                    title: 'Error',
                    status: 'error',
                    description: error.message,
                })
            }
        } finally {
            setLoading(false)
            updateSWR(`/api/voucher/${voucher_id}`)
        }
    }

    return { isLoading, mutate }
}

export async function sendExtensionRequestViaWhatsApp(id: number, status = 0) {
    return axios.post(
        `/api/voucher/send-extension-request/${id}?update_status=${status}`,
    )
}

export async function sendExtensionRequestViaSMS(id: number, status = 0) {
    return axios.post(
        `/api/voucher/send-extension-request-sms/${id}?update_status=${status}`,
    )
}

export async function sendExtensionRequestViaEmail(id: number, status = 0) {
    return axios.post(
        `/api/voucher/send-extension-request-email/${id}?update_status=${status}`,
    )
}
